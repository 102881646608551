@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Passions+Conflict&family=Lato&family=Poppins:wght@300&family=Phudu&family=Sacramento&family=Arimo:wght@400&display=swap');

/* variables */

:root {
  --overlayContainerHeight: 25em;
  --priceFontSize: 1.5em;
  --pricePadding: 1em;
  --navHeight: 4em;
  --telNavHeight: 2em;
  --sectionsHeight: 4em;
  --mediumFontSize: 1em;
  --smallFontSize: 0.5em;
  --mediumPadding: 1.5rem;
  --stampWidth: 10em;
  --appBoxShadow: 0 2px 4px rgba(86, 84, 148, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5); 
  --appMainColor: #e7c861;
  --appLightColor: rgba(192, 193, 218, 1);
  --appTransColor: rgba(0, 0, 0, 0.2);
  --appDarkGold: #eabd3c;
  --appGold: #e7c861;
  --appExtraLightGold: #f0eac5;
  --appExtraLightGoldTrans: #f0eac5d0;
  --appGradientBackground: linear-gradient(180deg, rgb(241, 237, 208,1) 10%, rgba(241, 237, 208,1) 80%, rgba(255,255,255,1) 100%);
  --appNavGold: #faf3c8eb;
  --appLightGold: #ebd79a;
  --appWhiteColor: rgba(255, 255, 255, 1); 
  --appPinkMedium: rgb(224, 178, 154);
  --appPinkLight: rgb(247, 217, 200);
  --appPinkTransLight: rgb(247, 217, 200, 0.7);
  --appPinkTrans:  rgba(224, 178, 154, 0.433);
  --appDarkGreenColor: rgba(11, 116, 77, 0.2);
  --appDarkGreenColorPlain: rgb(6, 58, 39);
  --appYellowStar: rgb(252, 213, 39);
  --appWhiteTransColor: rgba(255, 255, 255, 0.8);
  --appWhiteTransBtn: rgba(255, 255, 255, 0.4);
  --appLightGreenColor: rgba(142, 202, 156, 0.25);
  --oldAppLightGreenColor: #b7f2d57a;
  --appGreenColor: rgba(98, 172, 161, 1);
  --appGreenColorTrans: #7fa58ea8;
  --appLightGreenColorPlain: #e1eedd;
  --appDarkBlue: rgb(22, 22, 106);
  --appMiddleBlue: rgb(67, 74, 153);
  --appWarning: rgb(255, 69, 1);
  --appBlue: #565494;
  --appLightBlue: #c0c1da;
  --appBlackTrans:  #26271ca6;
  --appBlueTrans:rgba(22, 22, 106,0.8);
  --appCaution: rgba(11, 116, 77, 0.8);
  --appGoldGradient : linear-gradient(180deg, #f9f7eff6, #f9f7eff6 35%, rgba(241,220,26,0.936) 55%, #f9f7eff6 75%, rgba(18, 83, 137, 0.52) 100%);
  --appGreenGrandient : linear-gradient(217deg, #ebd79a8c, rgba(255,0,0,0) 70.71%),
  linear-gradient(127deg, #ebd79a, rgba(0,255,0,0) 70.71%),
  linear-gradient(336deg, #ebd79a, rgba(0,0,255,0) 70.71%);

  --navBckgColor: rgba(255, 255, 255, 1);
  --imgEffectDarkColor: rgba(0, 0, 0, 0.5);
  --imgBorderRadius: 2%;
  --overlayTop: 55%;
  --prezImageWidth: 150px;
  --prezImageHeight: 150px;
  --homeImageWidth: 450px;
  --homeImageHeight: 250px;
  --articleHeight: 450px;
  --articleWidth: 400px;
  --prezArticleImageWidth: 200px;
  --prezArticleImageHeight:200px;
  --h1FontSize: 1.5em;
  --h1Hero: 6em;
  --h2Hero: 3em;
  --articleFontSize: 0.9em;
  --cardWidth: 400px;
  --cardHeight: 300px;
  --p01ImageWidth: 400px;
  --p01ImageHeight: 300px;
  --carouselImgWidth: 100vw;
  --carouselImgHeight: 600px;
  --stampLeft: -40px;
/*fonts*/
  --fontMedColor: #eabd3c;
  --appBrownFont: #444632;
  --GreenfontMainColor: #7FA58E;
  --lightFontColor: #CAD2BA;
  --darkGreenFont:  rgb(3, 60, 38);
  --fontMainColor:rgb(22, 22, 106);  
  --appFfMain: 'Montserrat';
  --appFtTitle: 'Sacramento';
  --appCapsFont: 'Arimo';
  --appFfTitle: 'Passions Conflict';
  --appFfText: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


@keyframes rotate-baby-horse {
  0% {
   
    transform-origin: bottom;
    transform: rotateZ(-20);
   
  }
  100% {
   
    transform-origin: bottom;
    transform: rotateZ(20deg);
   
  }
}
@-webkit-keyframes rotate-baby-horse {
  0% {
  
    transform-origin: bottom;
    transform: rotateZ(-20);
    
  }
  100% {
 
    transform-origin: bottom;
    transform: rotateZ(20);
   
  }
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes scalepop {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scalepop {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes scalepopinv {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scalepopinv {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes birthAndDie{
  0%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  25%{
    opacity: 1;
    transform: scale(1.10) rotate(90deg) ;
  }
  50%{
    opacity: 1;
    transform: scale(1) rotate(180deg) ;
  }
  65%{
    opacity: 0.7;
    transform: scale(1.25) rotate(180deg);
  }
  75%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  80%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  100%{
    opacity: 0;
    transform: scale(0) rotate(90deg);
  }
  
}


@keyframes birthAndDie{
  0%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  25%{
    opacity: 0.7;
    transform: scale(1.10) rotate(90deg) ;
  }
  50%{
    opacity: 1;
    transform: scale(1) rotate(180deg) ;
  }
  65%{
    opacity: 1;
    transform: scale(1.25) rotate(180deg);
  }
  75%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  80%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  100%{
    opacity: 0;
    transform: scale(0) rotate(90deg);
  }
  
}
@-webkit-keyframes birthAndDieBckg{
  0%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  25%{
    opacity: 0.25;
    transform: scale(1.10) rotate(90deg) ;
  }
  50%{
    opacity: 0.25;
    transform: scale(1) rotate(180deg) ;
  }
  65%{
    opacity: 0.1;
    transform: scale(1.25) rotate(180deg);
  }
  75%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  80%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  100%{
    opacity: 0;
    transform: scale(0) rotate(90deg);
  }
  
}


@keyframes birthAndDieBckg{
  0%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  25%{
    opacity: 0.25;
    transform: scale(1.10) rotate(90deg) ;
  }
  50%{
    opacity: 0.25;
    transform: scale(1) rotate(180deg) ;
  }
  65%{
    opacity: 0.25;
    transform: scale(1.25) rotate(180deg);
  }
  75%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  80%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  100%{
    opacity: 0;
    transform: scale(0) rotate(90deg);
  }
  
}

@-webkit-keyframes birthAndDie02{
  0%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  25%{
    opacity: 1;
    transform: scale(1) rotate(90deg) ;
  }
  50%{
    opacity: 1;
    transform: scale(0.8) rotate(-90deg) ;
  }
  65%{
    opacity: 1;
    transform: scale(1) rotate(-180deg);
  }
  75%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  80%{
    opacity: 0;
    transform: scale(0) rotate(90deg);
  }
  100%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  
}
@keyframes birthAndDie02{
  0%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  25%{
    opacity: 1;
    transform: scale(1) rotate(90deg) ;
  }
  50%{
    opacity: 1;
    transform: scale(0.8) rotate(-90deg) ;
  }
  65%{
    opacity: 1;
    transform: scale(1) rotate(-180deg);
  }
  75%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  80%{
    opacity: 0;
    transform: scale(0) rotate(90deg);
  }
  100%{
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  
}
@-webkit-keyframes scalepoplight {
  0% {
    transform: scale(1) translateX(0px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
    transform: scale(2) translateX(70px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(0px);
  }
}
@keyframes scalepoplight {
  0% {
    transform: scale(1) translateX(0px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
    transform: scale(2) translateX(70px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(0px);
  }
}

@-webkit-keyframes scalefront {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scalefront {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes lifeRotate {
  0% {
    transform: rotateY(-15deg);
   
  }
  50% {
    transform: rotateY(0deg);
    
  }
  100%{
    transform: rotateY(15deg);
   
  }
}
@keyframes lifeRotate {
  0% {
    transform: rotateY(-15deg);
   
  }
  50% {
    transform: rotateY(0deg);
    
  }
  100%{
    transform: rotateY(15deg);
   
  }
}
.info-italic{
  padding: 1em;
  font-style: italic;
  color: var(--appGreenColor);
}
.home-share-img{
  width: 80%;
  animation: 3s linear 1s infinite alternate  lifeRotate;
 box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 1.5em;
}
.h2-contact{
  font-size: 35px;
}
.tel-number{ 
  opacity: 0;
}
.tel-number.active{
  opacity: 1;
}

.img-communication-container{
  width: 80%;
  margin: 0 auto;
  padding: 0.5em;
}
.trash{
  color: var(--appDarkGold);
  font-size: 1.5em;
  cursor: pointer;
}
.pdf-wrapper{
  width: 90%;
  margin: 0 auto;
}
.print-pdf-wrapper{
  width: inherit;
  margin: 0 auto;
}
.print-name-wrapper{
  display: grid;
  grid-template-columns: 30% 30% 30%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  width: 100%;
}
.name-wrapper{
  display: grid;
  grid-template-columns: 45% 45%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  width: 100%;
}
.date-wrapper{
  display: block;
  width: 100%;
  margin: 0 auto;
}
.print-coord-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}
.photo-communication{
  width: 200px;
  height: auto;
  border-radius: 20% 2% 20% 2%;
  box-shadow: var(--appPinkTransLight) 0px 10px 20px;
}
.img-communication-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0 auto;

}
.coord-wrapper{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
}
.civil-wrapper, .civil-wrapper-print{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  margin: 0 auto;
  width: 90%;
  position: relative;
}
.simple-center-wrapper{
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: baseline;
}
.p-justify{
  text-align: justify;
  position: relative;
}
.p-justify-2e{
  text-align: justify;
  position: relative;
  line-height: 2em;
  padding-left: 2em;
}
.print-contact-info{
  color: var(--appDarkBlue);
  font-weight: 600;
  font-size: 12pt;
}
.contact-civil-wrapper{
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  margin: 0 auto;
  position: relative;
  align-items: flex-start;
}
.animal-img-device{
  width: 100%;
  height: auto;
}
.obj-form-dispos{
  width: 100%;
  margin: 0 auto;
  margin-top: 20pt;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.contact-pdfform-textblock{
  position: relative;
  line-height: 2em;
  margin-top: 1em;

}
.contact-pdfprint-textblock{
  line-height: 1.5em;
margin-top: 1em;
}

.cgv-pad{
  display: inline;
  padding: 1em;
 
  
}
.input-pdffrom-group{
  display: flex;
  flex-direction: column;
}
.contact-pdfform-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.contact-pdfform-details{
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.contact-pdfprint-details{
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.obj-form-details{
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.civil-state{
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  align-content: space-around;
  justify-content: flex-end;
  width: 100%;
}
.contact-pdfform-textblock-wrapper{
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  align-content: space-around;
  justify-content: flex-end;
  width: 100%;
}
.pdf-logo-equilys, .pdf-logo-equilys-print{
  width: 100px;
  height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
  position: relative;
  display: block;
  margin-left: 2em;
  
}
.printpdf-logo-equilys{
  width: 100px;
  height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
  position: relative;
  display: block;
}
.accept-zone{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 450px;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-wrap: nowrap;
}
.print-accept-zone{
  display: flex;
  flex-direction: row;
  width: 210mm;
  height: 150pt;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-wrap: nowrap;
}
.pdf-icon-hidden{
  display: none;
}
.pdf-icon{
  width: 200px;
  height: 100px;
  -o-object-fit: fill;
     object-fit: fill;
  cursor: pointer;
  position: relative;
}
.info-print{
  position: absolute;
  top: 0;
  left: 50%;
  color: var(--appDarkBlue);
}
.btn-pdf-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
.important{
  font-weight: 600;
  text-transform: uppercase;
  color: var(--appCaution);
  position: relative;
}
.home-actu-date, .home-actu-date-open {
  font-feature-settings: "smcp";
  background-color: var(--appWhiteColor); 
  border: 2px groove var(--appGreenColor);
  border-radius: 50%;  
  color: #7fa58e;
  color: var(--appGreenColor);
  font-size: smaller;
  font-variant: small-caps;
  height: 150px;
  margin: 0 auto;
  padding: 1em;
  position: absolute;
  right: 0;
  top: -8em;
  display: flex;
  width: 150px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.clock-actu{
  -webkit-animation: 3s linear infinite rotate;
          animation: 3s linear infinite rotate;
}
.clock-actu-reverse{
  -webkit-animation: 3s linear infinite rotate;
          animation: 3s linear infinite rotate;
  animation-direction: reverse;
}
.subtitle-textblock{
  font-weight: 600;
  color: var(--appGreenColor);
  font-size: larger;
  margin: 0 auto;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
.main-actu-side-block{
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 1.5em;
}
.main-actu-compo-wrapper{
  position: relative;
  display: block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.activity-wrapper{
  width: 100%;
  display: flex;
  text-align: left;
  align-items: center;
}
.contact-radio-group{
  display: inline;
  margin-top: 1em;
  margin-bottom: 1em;
}
.printable-radio-group{
  display: inline;
}
.contact-strong{
  color: var(--appCaution);
}
.checkbox-input{
  display: flex;
  align-items: baseline;
}
.obj-form-details-toprint{
  width: 100%;
  display: flex;
}
.checkbox-group{
  text-align: left;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 0.5em; 
  margin-left: 1em;
}
.checkbox-group-print{
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}
button:active{
  -webkit-filter: brightness(1.5);
          filter: brightness(1.5);
}
button:disabled{
  background: var(--appBlackTrans);
  cursor:none;
}
.checkcook{
  color: var(--appCaution);
}
.contact-label{
  color: var(--appDarkBlue);
}
.contact-label.active{
  color: var(--appCaution);
}
button:disabled:hover{
  cursor:none;
  background-color: var(--appBlackTrans);
  color: var(--appBrownFont);
}
button{
  background: transparent;
  border: none;
}
.mentions-infos-container{
  line-height: 2em;
  margin: 1em;
  text-align: justify;
}
.tabs-prev{
  transform: rotate(180deg);
  font-size: 2em;
  color: var(--appGreenColor);
  margin-bottom: 1em;
}
.tabs-next{
  color: var(--appGreenColor);
  font-size: 2em;
  margin-top: 1em;
}
.other-wrapper{
  position: relative;
  transform: translateX(-1000px);

}
.separate{
  display: flex;
 }
/*sidenav*/
.sidenav-container{
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
 }

 .svg-learning{
  width: 40px;
  height: auto;
 }
 .scroll-wrapper{
  width: 100%;
  height: 100%;
  overflow: auto;
 }
 .link-pdf{
  margin-top: 5em;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  position: relative;
 }
 .simple-icon{
  color: var(--appDarkBlue);
  font-size: 3em;  
 }
 .form-simple-icon{ 
  font-size: 3em;  
 }
 .printform-simple-icon{
  font-size: 15pt;
 }
 .signatures-modes-wrapper{
  position: absolute;
  top: 60px;
  left: 20px;
  opacity: 1;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  background: var(--appWhiteColor);
  border: 1px solid var(--appBlackTrans);
  height: fit-content;
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  z-index: 5;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
 }
 
 .signatures-modes-hidden{
  position: absolute;
  top:0;
  left:-1000px;
  opacity: 0;
 }

.editable-pdf{
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}
.printable-pdf{
  width: 210mm;
  height: 297mm;
  position: relative;
  font-size: 11pt;
  margin: 0 auto;
  margin-right: 12pt;
  margin-right: 12pt;
}
 .pdf-printable{
  width: 100%;
  height: 100%;
 }
 .pdf-form-container{
  width: 100%;
  margin: 0 auto;
  color: var(--appDarkBlue);
 }
 .warning{
  color: var(--appWarning);
  font-weight: 500;
 }
 .sign-label, .left-sign-label, .right-sign-label, .activ-sign-label{
  z-index: 3;
  position: relative;
  background-color: var(--appWhiteColor);
 }
 .form-label, .left-form-label, .right-form-label, .activ-form-label{
  z-index: 3;
  position: relative;
 
 }
 .button-wrapper, .activ-button-wrapper{
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  font-size: 20px;
 }
 .activ-button-wrapper{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 8px 8px;
 }
 .button-wrapper{
  color: var(--appWhiteColor);
 }
 input[type="checkbox"]{
  border: 1px solid var(--appDarkBlue);
  width: 20px;
  height: 20px;
 }
 .activ-sign-label{
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
 }
 .sign-label{
  background-color: var(--appBlue);
  color: var(--appWhiteColor);
 }
 .form-label{
  background-color: var(--appGreenColor);
  color: var(--appWhiteColor);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-around;
 }
 .left-sign-label{
  border-radius: 10px 0px 0px 10px;
  background-color: var(--appBlue);
  color: var(--appWhiteColor);
 }
 .left-form-label{
  border-radius: 10px 0px 0px 10px;
  background-color: var(--appGreenColor);
  color: var(--appWhiteColor);
  border: 2px solid var(--appDarkGreenColor);
 }
 .right-sign-label{
  border-radius: 0px 10px 10px 0px;
  background-color: var(--appBlue);
  color: var(--appWhiteColor);
 }
 .right-form-label{
  border-radius: 0px 10px 10px 0px;
  background-color: var(--appGreenColor);
  color: var(--appWhiteColor);
  border: 2px solid var(--appDarkGreenColor);
 }
 .sign-tabs-wrapper{
  position: relative;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  display: flex;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  border: 1px double var(--appGreenColor);
  border-radius: 10px;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: stretch;
 }
 .form-tabs-wrapper{
  position: relative;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  display: flex;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  width: 100%;
  margin: 0 auto;  
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: stretch;
 }
 .signature-mode-wrapper{
  position: relative;
  display: block;
  margin: 0 auto;
  height: 100%;
  margin-top: 12pt;
 }
 .signatures-modes-container{
  display: block;
  position: absolute;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  height: -moz-fit-content;
  height: 450px;
  top: 0;
  left: 0;
  width: -moz-fit-content;
  width: 600px;

 }
 
 .com-animal-separation, .price-separation, .com-animal-formules-separation{ 
    height: 200px;
    width: 100%;
    color: white;
    position: relative;
    display: flex;
    font-size: 3em;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    padding-left: 2em;
}
.price-separation{
  background: var(--appWhiteColor);
  margin-top: 0em;
  
}
.com-animal-separation{
  background-color: var(--appPinkLight);
 }
 .com-animal-formules-separation{
  background-color: var(--appPinkLight);
 }
.df-r-fe{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: nowrap;
}
.com-modules-wrapper{
  display: flex;
  flex-direction: row;
}
.home-comModule{
  position: relative;
  opacity: 0;
  right: 150px;
  transition: 0.5s opacity linear, 0.5s right linear;
}
.home-comModule-training{
  position: relative;
  opacity: 0;
  top: 300px;
  transition: 0.75s opacity linear, 0.75s top linear;
}
.home-comModule.activ, .home-comModule-training.activ{
  opacity: 1 ;
  right: 0;
  top: 0;
  transition: 0.75s opacity linear, 0.75s right linear, 0.75s top linear;
}
.com-intro-wrapper{
 
  position: relative;
  margin: 0 auto;
  width: 100%;
}
.df-r-c{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
}
.f-s-title-price{
  color: var(--appGreenColor);
  top: -60px;
  position: relative;
  opacity: 0.5;
}


.f-s-title-price.active{
  opacity: 1;
  position: relative;
  top: 0;
  transition: top 0.8s linear;
}
.f-s-title{
  color: var(--appGreenColor);
  top: -60px;
  position: relative;
  opacity: 0.5;
}
.f-s-title.active{
  opacity: 1;
  position: relative;
  top: 0;
  transition: top 0.8s linear;
}
.titlecom-wrapper{
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: auto;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.bottom-appear{
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: auto;
  opacity: 0;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  top: 100px;
  transition: opacity 1s linear, top 1s linear;
}
.bottom-appear.active{
  transition: opacity 1s linear, top 1s linear;
  opacity: 1;
  top:0;
}
.right-appear{
  position: relative;
  transition: opacity 1s linear, right 1s linear;
  opacity: 0;
  right: -50px;
}
.right-appear.active{
  transition: opacity 1s linear, right 1s linear;
  opacity: 1;
  right: 2%;
}
.left-appear{
  position: relative;
  transition: opacity 1s linear, left 1s linear;
  opacity: 0;
  left: -50px;
}
.left-appear.active{
  transition: opacity 1s linear, left 1s linear;
  opacity: 1;
  left: 0;
}
 .drawing-zone{
  width: 400px;
  margin: 0 auto;
 }
 .pdf-form-title{
  color: var(--appGreenColor);
  text-transform: capitalize;
  font-size: 20px;
 }
 .pdf-printform-title{
  font-size: 14pt;
  color: var(--appGreenColor);
 }
 .img-to-print{
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: auto;
 }
 .pdf-form-hidden{
  display: none;
 }
 .pdf-print-container{
  width: 210mm;
  height: 297mm;
  position: relative;
  margin: 0 auto;
  background-color: var(--appWhiteColor);
  
 }
 .printable-h3{
  text-align: left;
  padding-left: 10pt;
  color: var(--appGreenColor);
 }
 .printable-h3 span{
  font-weight: 400;
 }
 .input-label-print.title{
  font-weight: 600;
  color: var(--appDarkBlue);
 }
 .pdf-print-title{
  width: 100%;
  margin: 0 auto;
  color: var(--appGreenColor);
 }
 .pdf-page{
  width: 210mm;
  height:297mm;
  margin: 0 auto;
 }
 .signature-img{
  margin: 0 auto;
  text-align: center;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
 }
 .address-wrapper{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto;
 }
 .print-address-wrapper{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto;
 }

 .place-g{
  display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    align-items: baseline;
 }
 .accept-wrapper{
  width: 100%;
  margin: 0 auto;
  position: relative;
 }
 .file-container{
  position: relative;
  width: 100%;
  height: fit-content;
 }
 .hidden-user-window, .ask-user-window{
  border-radius: 2px;
  position: absolute;
  background-color: var(--appWhiteColor);
  width: 80%;
  height: 500px;
  margin: 0 auto;
  top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: space-around;
  line-height: 2em;
  box-shadow: rgba(46, 98, 141, 0.35) 0px 5px 15px;
 padding-top: 1.5em;
 }
 .hidden-user-window{
  left: -100%;
  
 }
 .ask-user-window{
  left: 10%;
 
 }
 .pdf-container{
  width: 100%;
  height: inherit;
  margin: 0 auto;
 }
 .pdf-container-print{
  width: inherit;
  height: inherit;
  margin: 0 auto;
 }
 .pdf-print-wrapper{
  position: relative;
  margin-top: 20pt;
 }
 .pdf-footer{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: inherit;
 }
 .upload-logo-img{
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  background-color: rgba(255, 255, 255, 0.08);
  mix-blend-mode: color; 
  transition: transform 0.8s cubic-bezier(0.86,0,0.07,1);
}
.sign-canva{
  width: 100%;
  background-color: var(--appBlackTrans);
}
.type-canva{
  width: 100%;

}
.img-canvas{
  height: inherit;
  width: inherit;
}
.upload-input{
  opacity: 0%;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%; 
  top: 50%;
  z-index: 10;
}
.file-btn-fake{
  font-size: smaller;
  display: block;
  width: 60%;
  border-radius: 5%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  transform: translateZ(10px);
  top: 50%;
  padding: 0.5em;
  background: linear-gradient(217deg, rgba(0, 255, 234, 0.8), rgba(255,0,0,0) 70.71%),
            linear-gradient(127deg, rgba(0, 187, 255, 0.8), rgba(0,255,0,0) 70.71%),
            linear-gradient(336deg, rgba(125, 125, 187, 0.8), rgba(0,0,255,0) 70.71%);
  margin: 0 auto;
}

.file-btn-fake:hover{
  transform: scale(1.25);
}
.upload-input:hover{
  cursor: pointer;
}

.upload-logo-img:hover{
  background-color: white;
  mix-blend-mode: color;
  transform: scale(1.25);
}
 .pdf-page{
  border : 1px solid var(--appDarkGold);
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  margin-top: 5em;
  color: var(--appDarkBlue);

 }
/*scrollbars*/
/*actucompo*/
div.scroll-wrapper::-webkit-scrollbar, div.card-back::-webkit-scrollbar{
  height: 100%;
  width: 8px;
}
/*actubar*/
div.side-actu-compo-wrapper::-webkit-scrollbar{
  height: 100%;
  width: 8px;
}
div.side-actu-compo-wrapper::-webkit-scrollbar-track {
  background: var(--appWhiteColor);        /* color of the tracking area */
}
div.card-back::-webkit-scrollbar-track{
  background: transparent;
}
div.side-actu-compo-wrapper::-webkit-scrollbar-thumb {
  /* color of the scroll thumb */
  background-image: -webkit-linear-gradient(top,
                         #e4f5fc 0%,
                         #ffffff 50%,
                         #121562 51%,
                         #121562 100%);
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid whitesmoke;  /* creates padding around scroll thumb */
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
  
}
div.card-back::-webkit-scrollbar-thumb {
  /* color of the scroll thumb */
  background-image: -webkit-linear-gradient(top,
                         #e4f5fc 0%,
                         #ffffff 50%,
                         #1c945c8c 51%,
                         #12624ac3 100%);
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid whitesmoke;  /* creates padding around scroll thumb */
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
  
}
div.side-actu-compo-wrapper::-webkit-scrollbar-track-piece, div.card-back::-webkit-scrollbar-track-piece{ /*after trackbar*/
  width: 60%;
}
/* Handle on hover */
div.side-actu-compo-wrapper::-webkit-scrollbar-thumb:hover, div.card-back::-webkit-scrollbar-thumb:hover {
  background-image: -webkit-linear-gradient(top,
                         #ffffff 0%,
                         #ffffff 50%,
                         #2dcbfc 51%,
                         #2126ae 100%); 
}

/* witnessbar */
div.scrolled-div::-webkit-scrollbar {
  height: 10px; /* height of the entire scrollbar */
 
}

div.scrolled-div::-webkit-scrollbar-track {
  background: var(--appWhiteColor);        /* color of the tracking area */
}
div.scrolled-div::-webkit-scrollbar-thumb {
  /* color of the scroll thumb */
  background-image: -webkit-linear-gradient(top,
                         #e4f5fc 0%,
                         #ffffff 50%,
                         #121562 51%,
                         #121562 100%);
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid whitesmoke;  /* creates padding around scroll thumb */
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
  
}
div.scrolled-div::-webkit-scrollbar-track-piece{ /*after trackbar*/
height: 60%;
}
/* Handle on hover */
div.scrolled-div::-webkit-scrollbar-thumb:hover {
  background-image: -webkit-linear-gradient(top,
                         #ffffff 0%,
                         #ffffff 50%,
                         #2dcbfc 51%,
                         #2126ae 100%); 
}
.main-actu-compo-container{
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 3em;
  margin: 0 auto;
  background-color: var(--appWhiteColor);
}
.equilys-plume{
  width: 250px;
  height: auto;
  margin: 0 auto;
  position: relative;
  margin-top: 2em;
  margin-bottom: 2em;
}
.equilys-plume-deco{
  width: 150px;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 1em;
}

.witness-carousel-wrapper{
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--appWhiteColor); 
  /*  background: linear-gradient(180deg, rgba(11,109,113,0) 0%, rgb(237, 240, 242) 35%, rgb(255, 255, 255) 100%); */
}
.margin-div, .margin-div-alaune{
  width: 100%;
  background-color: var(--appWhiteColor);
}
.margin-div{
  height: 3em;
}
.margin-div-alaune{
  height: 400px;
}
.main-actu-compo-articles-container{
  display: flex;
  flex-direction: column;
  position: absolute;
  top:0;
  left:0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  min-height: 1000px;
}
.home-deco-wrapper{
   display: flex;
    border: none;
    width: 100%;
    height: 200px;
    background-image: url("../images/common/badgeCom.png");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position-x: right;
    background-position-y: bottom;
    background-blend-mode: color-dodge;
    padding: 0.5em;
    position: relative;
    margin: 0 auto;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    padding-left: 1.1em;
  
 
}
.arrow-wrapper{
  display: flex;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.article-signature{
  display: block;
  color: var(--appWhiteColor);
  font-family: var(--appCapsFont);
  text-transform: uppercase;
}
.article-details{
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1.5em;
 
}

.arrow-btn{
  border: none;
  background: transparent;
}
.arrow-svg{
  color: var(--appBlue);
}
button:hover{
  -webkit-filter: brightness(1.25);
          filter: brightness(1.25);
cursor: pointer;

}
.grecaptcha-badge {
  display: none;
}
.about-button{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 10em;
  padding: 1em;
  background: var(--appGoldGradient);
  cursor: pointer;
}
.App, .App-static {
  text-align: center;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: var(--appFfMain);
}
.App-static{
  overflow: hidden;
  position: static;
}

h1{
 font-size: 1.8em;
 font-family: 'Poppins';
}

h1.gold-flip{
  background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  font-size: 2em;
  margin-bottom: 1.5em;
}
h1.gold-article{
  font-size: var(--h1FontSize);
  background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: right;
  padding-right: 1em;
}
h1.gold-article-right{
  font-size: var(--h1FontSize);
  background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  padding-left: 1em;
}
h2.gold-witness{
  background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%);
  -webkit-background-clip: text;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5em;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}
.title-effect{
  -o-object-fit: contain;
     object-fit: contain;
  width: 80%;
  margin-bottom: 1em;
  transition: -webkit-filter 1s linear;
  transition: filter 1s linear;
  transition: filter 1s linear, -webkit-filter 1s linear;
}
.title-effect-intercepting{
  -o-object-fit: contain;
     object-fit: contain;
  width: 80%;
  opacity: 0;
  transition: opacity 0.5s linear;
}

h1.gold{
  background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%);
  background-clip: text; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  font-size: 2em;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}

.intro-price-text-wrapper{
  text-align: left;
  margin: 0 auto;
  padding: 1em;
  font-size: 16px;
}
.intro-price-container{
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
}
.gradient{
  background: linear-gradient(180deg, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
  width: 100vw;
  height: 15em;

}
.gradient-top{
  background: linear-gradient(0deg, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
  width: 100vw;
  height: 5em;
  margin-top: 10em;

}
.hero-price-title {
  position: absolute;
  display: flex;
  z-index: 5;
  font-family: var(--appFfTitle);
  font-size: 50px;
  font-weight: 500;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  color: var(--appWhiteColor);
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 0 auto;
  align-content: flex-start;
  margin-bottom: 1em;
  margin-left: 5em;
}
.intro-price-wrapper{
  position: relative;
  line-height: 2em;
  z-index: 2;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  font-weight: 600;
  font-size: large;
  color: var(--appDarkBlue);
  display: grid;
  grid-template-columns: 50% 45%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  align-items: center;
  background: var(--appWhiteColor);
  margin-top: var(--navHeight);
}
.intro-price-image{
width: 100%;
display: block;
position: relative;
margin: 0 auto;
transform: scaleX(-1);
border-radius: 10px;
box-shadow: rgba(46, 98, 141, 0.35) 0px 5px 15px;
}
.euro-style{
  vertical-align: super ;
}
h1.gold-prices{
  background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%);
  background-clip: text; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
 flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 2em;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}
.hero-text h1{
  font-family: var(--appFfTitle);
  padding-right: 0.5em;
  padding-left: 0.5em;
}
.hero-com h1{
  font-family: var(--appFfTitle);

}
.teaching-hero-text-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: flex-start;
}
.hero-text-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: flex-start;

}
.hero-text h2{
  font-size: 35px;
  font-weight: 600; 
  margin-left: 2em;
  background: linear-gradient(360deg,  #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

 
}
.h2-nav{
 
  font-size: 15px;
  color: var(--fontMainColor);
}

.home-article-img{
  -o-object-fit: contain;
     object-fit: contain;
  height: inherit;
  width: inherit;
}
.article-wrapper{
  position: relative;
  display: flex;
}


.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.svgHero {  
  width: 100%;
  height: auto;
  display: flex;
  resize: horizontal;
  
}

.App-link {
  color: #61dafb;
}
/*footer*/
.mentions{
  margin-bottom: 2em;
  margin-top: 5em;
}
.logo-footer{
  width: 60px;
  height: 60px;
  margin: 0 auto;  
  margin-top: 1em;
  margin-bottom: 1em;
}

.mentions-container{
  height: fit-content;
  position: relative;
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 33% 33% 33%;
  background: var(--appBlueTrans);
  flex-direction: column;
  padding-top: 2em;
  justify-items: center;
  overflow: hidden;
  opacity: 0;
  align-items: center
}
.mentions-container.active{ 
  opacity:1 ;
  transition: opacity 0.5s linear;
}
.sign-container{
  margin-top: 5em;
}
a{
  text-decoration: none;
  color: var(--appBlue);

}
a:hover{
  color: var(--appDarkGold);
}
h2.mentionslst-span{
  font-family: var(--appFfTitle);
  font-size: 50px;
  margin: 0 auto;
  font-weight: 500;
}
.mentionslst-span{
 color: var(--appWhiteColor);
}

.eb-wrapper{
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  width: 100%;
}
.eb-span{
  font-family: var(--appFfTitle);
  font-size: 1.5em;
  color:#FF3366;
}
.signature-mentions{
  font-family: var(--appFfTitle);
  color: var(--appGold);
  font-size: 2.5em;
}
.signature-mentions-title{
  font-family: var(--appFfTitle);
  font-weight: 500;
  font-size: 80px;
}
.eb-logo{
  height: 30px;
  width: 30px;
  position: relative;
  display: flex;
  margin: 0 auto;
  cursor: pointer;
}
.nav-element{

  width: 100%;
  margin: 0 auto;
  position: relative;

}

.mentions-wrapper, .mentions-wrapper-footer{
  display: block;
  line-height: 2em;
  font-size: 15px;
}
.mentions-site{
  color: var(--appDarkGold);
  font-style: italic;
  font-weight: 600;
}
.mentions-footer{
  position: relative;
  display: flex;
  width: 100%;
  height: inherit;
  font-size: 1em;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 2em;
  margin-top: 2em;
}
/*
actus*/

.side-actu-icon-pair{
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 3;
  top: 0;
  left: 0;
}
.side-actu-icon-impair{
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 3;
  top: 0;
  left: 0;
}
.main-actu-icon{
  position: relative;
  width: 100px;
  height: 100px;
  z-index: 3; 
  color: var(--appDarkGold);
  font-size: 1.5em;
  text-align: center;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.main-actu-compo-date{

  font-weight: 600;
    line-height: 2.5em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;  
  border-bottom: 2px groove var(--appBlue);
 /*  background: var(--appBlue); */
  /* box-shadow: rgb(0 0 0 / 24%) 0px 3px 1px; */
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  opacity: 1;
  width: 80%;
  transform: scale(1) translateX(0);
  transition: transform 0.5s ease-in;

}
.home-actu-date-wrapper{
  text-transform: capitalize;
}
.main-actu-compo-date-hidden{ 
 transform: scale(0) translateX(-500px);
 transition: transform 0.5s ease-out;
 opacity: 0;
 
}
.side-actu-compo-container{
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;


}
.side-separator{
  margin-top: 2em;
  margin-bottom: 2em;
  border-top: 1px solid var(--appGreenColor);
  width: 80%;
  position: relative;
  display: block;
}
.side-actu-compo-wrapper{

  height: 30em;
  overflow-y: auto;
  margin-right: 2em;
  overflow-x: hidden;
}

.h1.actu{
  background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%);
  background-clip: text; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
 flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 2em;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}
.h1.sideactu{
  background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%);
  background-clip: text; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
 flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 2em;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  width: 80%;
  margin-right: 2em;
}
.main-actu-compo-titleblock{
  position: relative;
  margin: 0.5em auto 1.5em;
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: 2em;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  background: var(--appBlue);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  
}
.wrapper-actualities{
  position: relative;
  display: grid;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.actu-points-wrapper{
  font-size: 30px;
  margin: 0;
  margin-left: 1em;
}
.enseignement-title{
  font-size: 50px;
  font-family: 'Passions Conflict';
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 0.75em;
}
.main-actu-intro{
  font-family: var(--appFfTitle);
  display: flex;
  flex-direction: row;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.main-actu-compo-articles-wrapper{
  margin: 0 auto;
  position: relative;
  display: flex;
  background-color: var(--appWhiteColor);
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-items: center;
  justify-content: center;
  align-content: center;
  grid-template-rows: auto;
  row-gap: 5%;
  margin-bottom: 3em;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.side-actu-compo-articles-wrapper{
  margin: 0 auto;
  display: flex;
  width: 100%;
  flex-direction: column;  
}
.main-actu-compo-title2{
  text-align: justify;
  font-family: var(--appFfTitle);
  font-size: 50px;
  font-weight: 500;
  position: relative;
}
.side-actu-compo-title2{
  text-align: justify;
  font-family: 'Phudu';
  font-size: 50px;
  text-align: center;
}

.main-actu-block-title, .main-actu-block-subtitle{  
  margin: 0 auto;
  width: 100%;
  display: flex;  
  line-height: 2em;
  margin: 0 auto;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: 1.5em;
  color: var(--appWhiteTransColor);

}
.main-actu-block-title, .main-actu-block-subtitle{
  text-transform: uppercase;
}
.main-actu-block-subtitle{
  text-transform: capitalize;
}
.main-actu-compo-title2-pair{
  text-align: left;
}
.main-actu-compo-title2-impair{
  text-align: right;
}
.intro-further-actu{
  line-height: 2em;
}
.main-actu-compo-message{ 
  font-size: larger;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
}
.partage-article-container{
 
  margin-top: 20em;
  
}

.partage-article-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.partage-article-wrapper{
  margin-top: 3em;
  
}
.partage-separation{
  font-family: var(--appFtTitle);
  position: relative;
  font-size: 30px;
  width: 100%;
  margin: 0 auto;
  color: var(--appWhiteColor);
  background-color: var(--appGreenColor);
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;  
}

.partage-article-title{
  position: relative;
  font-family: var(--appFtTitle);
  font-size: 40px;
  color: var(--appWhiteColor);
  width: 100%;
  margin: 0 auto;
  top: 50px;
  opacity: 0;
  transition: opacity 0.5s linear, top 1s linear;
}
.partage-article-title.active{
  font-family: var(--appFtTitle);
  font-size: 40px;
  color: var(--appWhiteColor);
  width: 100%;
  margin: 0 auto;
  opacity: 1;
  top: 0;
  transition: opacity 0.5s linear, top 1s linear;
  
}

.practice-button-wrapper{
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: nowrap;
}
.partage-articles-intro{
  display: block;
  margin: 0 auto;
  width: 80%;
  text-align: justify;
 
  
}
.partage-article-data-title{
  font-family: var(--appFtTitle);
  font-size: 40px;
  color: var(--appGreenColor);
  width: 100%;
  margin: 0 auto; 
}
.partage-practice{
  margin-top: 1em;
}
.partage-article{
  position: relative;
  display: grid;
  grid-template-columns: 40% 55%;
  width: 100%;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  /* border-bottom: 2px groove var(--appGreenColor);
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 10px; */
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 50px; 
  margin-top: 50px;
}
.main-actu-compo-article{
  position: relative;
  display: grid;
  grid-template-columns: 40% 55%;
  width: 100%;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  /* border-bottom: 2px groove var(--appGreenColor);
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 10px; */
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 50px; 
  margin-top: 50px;
}
.main-actu-compo-article:nth-child(2n){
  grid-template-columns: 55% 40%;
}
.side-actu-compo-article-imgless{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  border: 2px solid var(--appGreenColor);
  min-height: 15em;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5em;
}
.side-actu-compo-article{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  border: 2px solid var(--appGreenColor);
  min-height: 15em;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5em;

 
}
.side-actu-textwrapper{
  display: flex;
  flex-direction: column;

}
.formules-pagetitle{
  background-color: var(--appWhiteColor);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.main-actu-compo-img, .main-actu-compo-img-right{
  width: 100%;
  height: inherit;
  -o-object-fit: cover;
     object-fit: cover;
  

}
.main-actu-compo-img{
  border-radius: 10px 10px 10px 10px;
  transform: rotateY(20deg);
  box-shadow: 10px 15px rgba(1, 1, 1, 0.1);
}
.main-actu-compo-img-right{
  border-radius: 10px 10px 10px 10px;
  transform: rotateY(-20deg);
  box-shadow: 10px 15px rgba(1, 1, 1, 0.1);
}
/*
tarifs*/
.price-container{
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  margin: 0 auto;
  
  
}
.price-fixed-background{
  position: relative;
  z-index: -1;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: fit-content;
  background-image: url("../images/common/priceBackground.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: top;
  margin-bottom: 2em;
}

.price-wrapper, .price-details-wrapper{
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(250px, 1fr));
  -moz-column-gap: 5%;
       column-gap: 5%;
  row-gap: 80px;
  width: 90%;
  margin: 0 auto; 
  padding: 0.25em;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin-bottom: 2em;
  background-color: transparent;
}
.price-details-wrapper{
  background-color: var(--appWhiteColor);
  margin-bottom: 0em;
  padding-bottom: 3em;
}
.price-training-wrapper{
  display: block;
  margin: 0 auto;
  width: 80%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  background-color: transparent;
}
.price-article{
  position: relative;
  line-height: 1.5em;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  width: 100%;
  height: 100%;
  background-color: var(--appWhiteColor);
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: nowrap;
}
.price-img-wrapper{
  width: 100%;
  height: 250px;
  position: relative;
}
.price-img{
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.price-col-label{
   
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  display: flex;
  min-width: 150px;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  line-height: 2em;
  font-style: oblique;
  color: var(--appDarkBlue);
  font-size: 1.2em;
  padding: 0.5em;
  margin-top: 1em;
}
.price-col{  
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  display: flex;
  min-width: 150px;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  line-height: 1.5em;
  padding: 0.2em;
}
.price-col#details{
  color: var(--appGreenColor);
  margin-top: 1.5em;
  font-weight: 500;
  font-size: larger;
  font-style: oblique;
}
.price-col-price{
  display: block;
  width: 100%;
  margin: 0 auto;
  font-size: 2em;
  font-family: var(--appFfMain);
  font-weight: 500;
  color: var(--appDarkBlue);
  line-height: 1em;
}
.price-col-title p{
  color: var(--appWhiteColor);
  position: relative;
  font-size: 1.2em;
}
.price-col-title#live p{
  color: var(--appWhiteColor);
  position: relative;
  font-size: 1.2em;
}
.price-col-title{
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin:0;  
  background-color: var(--appGreenColor);
  /* border-radius: 10px 10px 0 0; */
  width: 100%;
  height: 33%;
 
}

.price-col-title#com, .f-s-title-price#com{  
  -webkit-filter: brightness(1.25) contrast(1.1);  
          filter: brightness(1.25) contrast(1.1);
}
.price-col-title#lessons, .f-s-title-price#lessons{  
  -webkit-filter: brightness(1) contrast(1);  
          filter: brightness(1) contrast(1);
}
.price-col-title#training, .f-s-title-price#training{  
  -webkit-filter: brightness(0.8) contrast(2);  
          filter: brightness(0.8) contrast(2);
}
.price-col-title#live, .f-s-title-price#live{
  -webkit-filter: brightness(0.8) contrast(1.1);  
          filter: brightness(0.8) contrast(1.1);
}
.price-col-title#video, .f-s-title-price#video{
  -webkit-filter: brightness(0.8) contrast(1.5);  
          filter: brightness(0.8) contrast(1.5);
}
.note-wrapper{
  background-color: var(--appWhiteColor);
}
.asterisque{
  font-size: 30px;
  padding: 0.5em;
  color: var(--appGreenColor);
}
.price-details{
  position: relative;
 
}
.price-details-simple{
  position: relative;
}
.price-article-bottom{
  position: relative;
  line-height: 1.5em;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 10px;
  width: 80%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: transparent;
  min-height: 15em;
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: nowrap;

}
.points-wrapper{
  margin: 0 auto;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  flex-direction: row;
}
/*contact*/
.intro-contact{
  width: 100%;
  display: block;
  position: relative;
  line-height: 2em;
  margin: 0 auto;
  margin-top: 2em;
  margin-bottom: 2em;

}
.main-actu-main-block{
  height: 300px;
  width: 100%;
  display: block;
  z-index: 3;
  perspective: 2000px;
  perspective-origin: right;
  margin: 0 auto;
  margin-bottom: 1em;
}
.contact-form-container{
  display: grid;
  position: relative;
  grid-template-columns: 45% 45%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  width: 80%;
  margin: 0 auto;
}
.sign-form-container{
  display: grid;
  position: relative;
  grid-template-columns: 45% 45%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  width: 80%;
  margin: 0 auto;
}
.contact-image-wrapper{
  display: flex;
  width: 100%;
  border-right: 2px solid var(--appCaution);
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.sign-image-wrapper{
  display: flex;
  width: 100%;
  border-right: 2px solid var(--appCaution);
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-right: 2em;
}
.sign-title{
  color: var(--appDarkBlue);
  width: 100%;
  position: relative;
  display: block;
  margin-top: 2em;
  margin-bottom: 2em;
  font-weight: 600;
}
.photo-sign{
  width: 100%;
}
.photo-contact{
  -o-object-fit: contain;
     object-fit: contain;
  margin: 0.5em;
  width: 80%;
  height: inherit;
  transform: scaleX(-1);
}
.contact-input-wrapper{
  display: flex;
  text-align: justify;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.contact-button-wrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 3em;

}
.input-label{
  color: var(--appDarkBlue);
  padding-bottom: 1em;
  font-size: 14px;
}
.input-label-print{
  color: var(--appDarkBlue);
  padding-bottom: 10pt;
  padding-left: 10pt;
}
.contact-form-wrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 80%;
  margin: 0 auto;
  margin-right: 2em;
}

.form-input{
  border: none;
    border-bottom: 1px solid var(--appBlue);
    width: 100%;
    margin: 0 auto;
    line-height: 2em;
    background: transparent;
    margin: 0 auto;
    margin-bottom: 2em;
    width: 80%;
    display: block;
    position: relative;

}
.sign-input-wrapper{
  display: flex;
  text-align: justify;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.social-icons-wrapper{
  display: flex;
  width: 80%;
  flex-direction: column;
  margin: 0 auto;
}
.a-contact{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.icon-line, .icon-line-footer{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}
.icon-line-footer{
  color: var(--appWhiteColor);
}
.outline-icons{
  height: 50px;
  width: 50px;
  color: var(--appDarkGold);
}
.form-input:focus{
  outline: none;
  border-bottom: 1px solid var(--appCaution);
  background: transparent;
 
}
.form-input:active{
  outline: none;
  border-bottom: 1px solid var(--appCaution);
  background: transparent;

}
.form-contact-ta{
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}
.form-contact-ta:active{
  outline: none;
  border: 1px solid var(--appCaution);
}
.form-contact-ta:focus{
  outline: none;
  border: 1px solid var(--appCaution);
}
/* home */
.title-wrapper{
  margin: 0 auto;
  padding: 0;
}
.alaune-title-wrapper{
  position: relative;
  display: block;
  margin-bottom: 3em;
}
.alaune-intro{
  margin-bottom: 1em;
 
}
h1.alaune-title{
  color: var(--appDarkGold);
}
.alaune-container{
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: 0 auto;
  align-self: center;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(241, 245, 243) 7%, rgb(232, 240, 236) 80%, rgba(255,255,255,1) 100%);
}
.transition{
  height: 300px;
  width: 100%;
  position: relative;
  /* background: linear-gradient( rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 45%, rgba(255,255,255,0) 75%, rgba(255,255,255,0.25) 100%); */
 background: transparent;
}
.home-article-container{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
 
}
.home-container{
  position: relative;
  display: block;
  min-height: 100vh;
  opacity:1;
  transition: opacity 1s linear;
  background: linear-gradient( rgba(255,255,255,1) 0%, rgba(255,255,255,1) 5%,  rgba(255,255,255,0) 14%, rgba(255,255,255,0) 87%, rgba(255,255,255,0) 95%, rgba(255,255,255,0) 100%);
 /* background: transparent; */
}
.home-container-start{
  position: relative;
  display: block;
  min-height: 100vh;
  opacity: 0;
  transition: opacity 1s linear;
  /* background-color: var(--appDarkGreenColor); */
  background: transparent;
}

.enseignement-container{
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.partage-article-first{
 position: relative;
    border-bottom: 1px solid var(--appGreenColor);
    border-radius: 5px;
    border-right: 1px solid var(--appGreenColor);

}

.partage-article-practice{
  text-align: left;
  width: 95%;
  margin: 0 auto;
  line-height: 2em;
}

.partage-article-content{
  line-height: 2em;
  position: relative;
  display: block;
  text-align: justify;
  width: 90%;
  margin: 0 auto; 
  color: var(--appGreenColor); 
  opacity: 0.5;
  left: -100px;
  transition: color 1s linear, opacity 1s linear, left 1s linear;
}
.partage-article-content.active{
  color: var(--appBrownFont);
  opacity: 1;
  left: 0;
  background: transparent;
  transition: color 1s linear, opacity 1s linear, left 1s linear;
}
.comwrapper{
  margin: 0 auto;  
  width: 100%;
  max-width: 100vw;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: 3em;
}
.img-dogs{
  width: 150px;
  height: 150px;
}
.com-container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: var(--appWhiteColor);
 
}
.com-container-trans{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center; 
  background-blend-mode: hard-light;
  background-color: var(--appPinkTrans);
  background-image: url("../images/com/background.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: center;
  background-position-y: center;
}
.presta-tut{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.presta-container-left, .presta-container-left.active{
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  height: 3px;  
  width: 40%;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center; 
  background-blend-mode: multiply;
  background-color: var(--appBlueTrans);
  background-image: url("../images/com/background.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: center;
  background-position-y: center;
  transform: scaleX(1);
}
.presta-container-left{
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.75s linear;
}
.presta-container-right.active{
  transform: scaleX(1);
  transform-origin: right;
  transition: transform 0.75s linear;
}
.presta-container-left.active{
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.75s linear;
}
.presta-container-right{
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.75s linear;
}
.presta-container-right, .presta-container-right.active{
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  height: 3px;  
  width: 40%;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center; 
  background-blend-mode: multiply;
  background-color: var(--appBlueTrans);
  background-image: url("../images/com/background.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: center;
  background-position-y: center;
}
.enseignement-article-container{
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 30% 30% 30%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  text-align: justify;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100%;
}
.enseignement-text{
  line-height: 1.5em;
  text-align: justify;
  width: 80%;
  position: relative;
  display: block;
  margin: 0 auto;
}
.enseignement-article{
  margin: 0 auto;
  margin-top: 2em;
}
.enseignement-article-wrapper-latest{
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2em;
  background-color: var(--appLightGreenColor);
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 20px 5px 20px 5px;
  width: 100%;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  padding: 0.5em;
  min-height: 40em;
  position: relative;
 
}
.enseignement-article-wrapper{
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2em;
  background-color: var(--appLightGreenColor);
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 20px 5px 20px 5px;
  width: 100%;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  padding: 0.5em;
  min-height: 40em;
  position: relative;
  
  
}
/* .enseignement-article-wrapper::before{
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  top: 50px;
  left: 50px;
  transform: scaleX(-1);
  background-image: url("../svg/leaves.svg");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-x: right;
  background-position-y: bottom;
  background-blend-mode: luminosity;
  opacity: 0.25;
} */
.enseignement-article-wrapper:nth-child(1)::before{
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  transform: scaleX(1);
  bottom: 0;
  right: 0;
  background-image: url("../images/enseignement/fond.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: right;
  background-position-y: bottom;
  -webkit-filter: grayscale();
          filter: grayscale();
  background-blend-mode: luminosity;
  opacity: 0.35;
}
.enseignement-article-wrapper:nth-child(3)::before{
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  transform: scaleX(1);
  bottom: 0;
  right: 0;
  background-image: url("../images/enseignement/fondLonguesRenes.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: right;
  background-position-y: bottom;
  -webkit-filter: grayscale();
          filter: grayscale();
  background-blend-mode: luminosity;
  opacity: 0.35;
}
.enseignement-article-wrapper:nth-child(2)::before{
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  transform: scaleX(1);
  bottom: 0;
  right: 0;
  background-image: url("../images/enseignement/fondEquifun.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: right;
  background-position-y: bottom;
  -webkit-filter: grayscale();
          filter: grayscale();
  background-blend-mode: luminosity;
  opacity: 0.35;
}
.enseignement-article-wrapper-latest:nth-child(1)::before{
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  transform: scaleX(1);
  bottom: 0;
  right: 0;
  background-image: url("../images/enseignement/fondDress.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: right;
  background-position-y: bottom;
  -webkit-filter: grayscale();
          filter: grayscale();
  background-blend-mode: luminosity;
  opacity: 0.35;
}
.enseignement-article-wrapper-latest:nth-child(3)::before{
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  transform: scaleX(1);
  bottom: 0;
  right: 0;
  -webkit-filter: grayscale();
          filter: grayscale();
  background-blend-mode: luminosity;
  
  background-image: url("../images/enseignement/fondObstacle.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: left bottom;
  background-position-y: bottom;

  opacity: 0.25;
}
.enseignement-article-wrapper-latest:nth-child(2)::before{
  width: 100%;
  height: 80%;
  content: '';
  position: absolute;
  transform: scaleX(1);
  bottom: 0;
  right: 0;
  background-image: url("../images/enseignement/fondExterieur.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: right;
  background-position-y: bottom;
  -webkit-filter: grayscale();
          filter: grayscale();
  background-blend-mode: luminosity;
  opacity: 0.5;
}
.enseignement-intro{
  display: block;
  margin: 0 auto;
  padding: 2em;
  position: relative;
  line-height: 2em;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
 
}

.actu-container{
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: var(--appWhiteColor);
  background-image: url("../images/common/signIn.jpeg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-x: center;
  background-position-y: bottom;
 
}
.actu-hole{
  width: 80%;
  height: 300px;
  background: transparent;
  margin: 0 auto;
}
.badge-wrapper{  
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  margin-top: 1em;
  border-radius: 50%;
 
  /* box-shadow:  rgba(7, 101, 52, 0.22) 0px 0px 0px 3px, rgba(10, 16, 14, 0.05)  0px 0px 0px 15px inset; */
 /*  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
 box-shadow: var(--appLightGreenColor) 0px 0px 0px 3px,  rgba(122, 122, 122, 0.5) 0px 0px 0px 6px
}
.badge{
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: block; 
  background-color: var(--appLightGreenColor);
  -webkit-clip-path: circle();
          clip-path: circle();
  position: relative;
  background-blend-mode:color-dodge;
  /* border: 3px solid var(--appLightGreenColor); */
 
}
.svg-mask{
  mask: url("../svg/tribal.svg");
  width: 350px;
  height: 350px;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 350px;
  mask-origin: content-box;
  mask-type: luminance; /* white = transparent, grays = semi-transparent, black = opaque */
  mask-type: alpha; /* transparent areas of the image let image through, otherwise not */
}
.clip-svg{
  width: 120px;
  height: 120px;
  clip-path: url(#myClip);
}
.clip-svg-com{
  width: 200px;
  height: 200px;
  clip-path: url(#myClipCom);
}
.img-com-autonomie{
  width: 80%;
  height: auto;
  position: relative;
  margin: 0 auto;
}
.img-presta-title{
  width: 90%;
  height: 600px;
  position: relative;
  margin: 0 auto;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.com-animal-formules-txt{
  position: relative;
  margin: 0 auto;
 
}
.badge::before{
  position: absolute;
  display: block;
  content:'';
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-image: url("../images/common/badgeCom.png");
  opacity: 0.8;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: right;
  background-position-y: bottom;
  
}
.home-intro{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.hero-wrapper{
  display: flex;
    width: 100%;
    height: auto;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    /* transform-origin: top;
    transform: translateY(0); */
    opacity: 1;
    transition: opacity 1s linear;
    position: relative;
}
.start-hero-wrapper{
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  transform-origin: top;
  /* transform: translateY(-100%); */
  opacity: 0;
  transition: opacity 0.5s linear;
  position: relative;
}
.hero-text, .hero-com{
  position: absolute;
  z-index: 4;
  top: inherit;
  left: 0;
  text-align: justify;
  width: inherit;
  height: 100%;
  background-image: linear-gradient(360deg, rgba(4 55 96 / 51%), rgba(255,0,0,0) 80.71%);
}

.hero-title, h1.hero-com-title{
  color: var(--appWhiteColor);
  font-size: 100px;
  font-weight: 100;
  width: 100%;
}
h1.hero-com-title{ 
  text-align: right;
  margin-bottom: 0;
}
.stable-title{
  position: absolute;
  z-index: 10;
  top: 30%;
  color: var(--appWhiteColor);
  font-family: var(--appFfTitle);
  font-size: 100px;
  width: 100%;
  margin: 0 auto;
 
}
h2.hero-com-title{
  color: var(--appPinkLight);
  font-size: 50px;
  width: 100%;
  text-align: right;
}
.masked-article{
  background-color: var(--appLightColor);

}
.home-bubble{
  position: absolute;
}
.bubbleWrapper{
  position: absolute;
  display: flex;
  z-index: 2;
  top: 5em;
  left: -10em;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;

}
.textWrapper{
  z-index: 3;
}

.article-text{
  color:rgb(22, 22, 106);
  
}
.article-wrapper#offsetInside{
  margin-top: 5em;
  margin-left: 10em;
}
.text-article{
  display: flex;
  position: relative;
  width: inherit;
  height: inherit;
  padding: 1em;
  text-align: justify;
  flex-direction: column;
  align-items: flex-end;
}
.home-article-img-wrapper{
  position: relative; 
  z-index: 5;
}
.home-title-img-wrapper{
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.prez-img-wrapper{
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.text-image-article{
display: flex;
padding: 0.1em;
padding-left: calc(var(--prezArticleImageWidth)/2.5);
}
.text-block-article{
  display: flex;
  padding: 1em;
  text-align: justify;
  line-height: 2em;
  width: 80%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  
}
.article-prez-img-wrapper{
  display: flex;
  width: var(--prezImageWidth);
  height: var(--prezImageHeight);
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
 
}
.article-prez-container{
  width: 100%;
  height: 100%;
}

.article-card-wrapper{
  position: relative;
  width: inherit;
  height: inherit;
  position: relative;
  display: flex;
}
.article-card-wrapper-right{
  position: relative;
  width: 100%;
  height: inherit;
  position: relative;
  display: flex;
  transform: translateX(-3em);
  transform: translateY(4em);
}
.article-card-wrapper:nth-child(2){
  top: 60px;
  left: 60px;
}
.training-title{ 
  font-weight: 500;
  font-variant: small-caps;
  color: var(--appDarkGold);
  font-size: 1.2em;  
}
.training-container{
  margin-top: 2em;
  display: block;
  position: relative;
}
.training-wrapper{
  line-height: 2em;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  height: 250px;
  margin: 0 auto;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 4em;
}
.training-wrapper-imgless{
  line-height: 2em;
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  height: 250px;
  margin: 0 auto;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  align-content: center;

}

.training-group{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-top: 1em;
  margin-bottom: 1em;
  height: inherit;
  width: 100%;
  /* background: linear-gradient(180deg, rgb(90, 121, 96) 0%, rgba(15,121,9,1) 35%, rgba(8,144,28,0.3478641456582633) 100%); */
}


.training-image{
  width: 100%;
  height: inherit;
  margin: 0 auto;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
  position: relative;
}
.training-date-wrapper{
  position: relative;
  display: block;
  height: 200px;
  margin: 0 auto;
}
.alaune-text-wrapper{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.training-date{
  font-variant: small-caps;
  color: var(--appLightBlue);
  position: absolute;
  top:0;
  left: 0;
  margin: 0 auto;
  height: 150px;
  width: 150px;
  padding: 1em;
  border-radius: 50%;
  background-color: var(--appDarkBlue);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;

}
.alaune-calendar{
  font-size: 30px;
  padding: 0;
 
}
.alaune-wrapper{
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  margin-top: 2em;
}
.home-article-wrapper{
  display: grid;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  grid-template-columns: repeat( auto-fit, minmax(calc(var(--articleWidth)*1.5), 1fr) );
  width: 80%;
  margin: 0 auto;
  margin-left: calc(var(--prezArticleImageWidth));
  margin-bottom: 10em;
}
.home-prez{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
 
}
.home-prez-wrapper{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
.home-prez-image{
  width: var(--prezImageWidth);
  height: var(--prezImageHeight);
  position: relative;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 5;
}
.home-title-image{
  width: var(--homeImageWidth);
  height: var(--homeImageHeight);
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;

}
.home-title-image-flip{
  width: var(--homeImageWidth);
  height: var(--homeImageHeight);
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
  transform: scaleX(-1);
}
.prez-title-image{
    width: var(--prezImageWidth);
    height: var(--prezImageHeight);
    position: absolute;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: 5;
}
.prez-article-title-image{
  width: inherit;
  height: inherit;
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 5;
  top: calc(var(--articleHeight)/4);
  left: calc(var(--articleWidth)/-3);
}
.home-prez-title-mask{
  width: calc(var(--prezArticleImageWidth)*1.25);
  height: calc(var(--prezArticleImageHeight)/2);
  background-color: var(--appCaution);
  position: relative;
  top: -5em;
}
.prez-article-title-image-right{
 
  width: inherit;
  height: inherit;
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 5;
  top: calc(var(--articleHeight)*1/6);
  right: calc(var(--articleWidth)/-1);
}
.article-prez-wrapper-right{
  padding-bottom: 1em;
  min-height: var(--articleHeight);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: var(--articleWidth);
  padding-right: calc(var(--prezArticleImageWidth)/2);
  border: 1px solid var(--appCaution);
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: space-around;
  align-items: center;
  text-align: left;
}
.article-prez-wrapper{
  min-height: var(--articleHeight);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: var(--articleWidth);
  text-align: right;
  border: 1px solid var(--appCaution);
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 1em;
}
.article-prez-text{
 width: 100%;
 height: 100%;
 display: block;
 margin: 0 auto;
 text-align: justify;
 font-size: var(--articleFontSize);
 line-height: 2em;
}
.home-title-mask{
  width: calc(var(--homeImageWidth)*1.15);
  height: calc(var(--homeImageHeight)/1.75);
  background-color: var(--appCaution);
  position: relative;
  top: calc(var(--homeImageHeight)/2);
 
}
.home-prez-mask{
  width: calc(var(--prezImageWidth)*1.25);
  height: calc(var(--prezImageHeight)/2);
  background-color: var(--appCaution);
  position: relative;
  top: -5em;
}

.yinyang{
  height: 5em;
  width: auto;
}
.yinYang-wrapper{
  display: flex;
  align-items: center;
}
.home-article{
  position: relative;
  display: flex;
  width: 80%;
  margin: 0 auto;
  height: auto;
  background-color: var(--appWhiteColor);
  border: 1px solid var(--appCaution);
  text-align: justify;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  
}
/*NEW OTHERS*/
.home-animal-container{
  display: grid;
  grid-template-columns: 10% 65% 21%;
  -moz-column-gap: 2%;
       column-gap: 2%;
  line-height: 2em;
  align-items: center;
  align-content: center;
  justify-items: center;
  position: relative;
  margin: 0 auto;
  justify-content: center;
 
}
.module-wrapper{
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 80%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  z-index: 50;
  position: relative;
}
.com-pdf-wrapper{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}
.com-download-subtitle{
  font-variant: small-caps;
  font-size: 1.25em;
}
.download-info{
  color: var(--appCaution);
  font-style: italic;
}
.com-animal-download-zone{
  display: flex;
  flex-direction: row;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}
.com-animal-details{
  display: block;
  margin: 0 auto;
  width: 100%;
}
.module-img{
  width: auto;
  height: 250px;
}
.home-com-container{
  background: transparent;
  background-color: var(--appWhiteColor);
}
.home-animal-first-group{
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.home-animal-hidden-group{
  width: 100%;
  height: 0;
  position: relative;
}
.home-animal-hidden{
  position: absolute;
  height: 500px;
  width: 4px; 
  margin: 0 auto;  
  background-color: var(--appPinkMedium);
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 1.5s linear;
}

.home-animal-img-button-wrapper{
  position: relative;
  width: 100%;
 
}
.img-com-position{
position: relative;
}
.home-intro-text-image{
  position: absolute;
  width: inherit;
  height: auto;
  transform: scale(0.8);
  transition: transform 1s linear;
  right: -20em;
  bottom: 0;
  transform-origin:  right;
}
.home-intro-text-anim{
  transform: scale(0.75) translateX(-10%);
  transform-origin:  right;
  transition: transform 1s linear;
  position: absolute;
  width: inherit;
  height: auto;
  bottom: 0;
  right: -20em; 
 
}
.home-animal-anim{
  position: absolute;
  height: 1000px;
  width: 4px; 
  margin: 0 auto;  
  background-color: var(--appPinkMedium);
  transform-origin: top;
  transform: scaleY(1);
  transition: transform 1.5s linear;
  
}

.home-animal-wrapper, .home-animal-wrapper2{
  position: relative;
  display: block;
  padding-top: 8em;
  text-align: left;   
  padding: 2em;
  margin: 0 auto;
  margin-top: 2em;
  border-radius: 50px;
  width: 80%; 
}
.home-animal-wrapper{
  background-color: var(--appPinkMedium);
  color: var(--appWhiteTransColor);
}
.effect-wrapper{
  width: 100%;
  height: 100%;
  position: static;
}
.com-animal-band{
  background: transparent;
  width: 100%;
  height: auto;
  position: relative;
}
.com-animal-wrapper5-txt{
  padding: 1em;
  width: 80%;
  margin: 0 auto;
}
.com-animal-wrapper5{
  position: relative;
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 100%;
  line-height: 2em;
  border-left: 1px solid var(--appGreenColor);
  border-bottom: 1px solid var(--appGreenColor);
  border-radius: 30px;
 
  text-align: justify;
  padding: 0.5em;
  background-color: var(--appWhiteColor);
}
.com-animal-wrapper3{
  position: relative;
  width: 90%;
  height: 100%;
  line-height: 2em;
  border-left: 1px solid var(--appGreenColor);
  border-bottom: 1px solid var(--appGreenColor);
  border-radius: 30px;
  font-size: 1.1em;
  text-align: justify;
  padding: 0.5em;
  background-color: var(--appWhiteColor);
}
.com-animal-wrapper4{
  position: relative;
  width: 90%;
  height: 100%;
  line-height: 2em;
  border-left: 1px solid var(--appGreenColor);
  border-bottom: 1px solid var(--appGreenColor);
  border-radius: 10px;
  font-size: 1.2em;
  margin-bottom: 2em;
}
.com-animal-formules-container{
  display: flex;
  text-align: justify;
  line-height: 2em;
  width: 95%;
  height: 100%;
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: nowrap;
}

.com-animal-formules-wrapper{
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-wrap:nowrap;
}
.com-animal-formules-title-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0 auto;
  align-items: flex-end;
}
.com-animal-wrapper{
  position: relative;
  display: block;
  padding-top: 8em;
  text-align: left;
  padding: 2em;
  margin: 0 auto;
  margin-top: 2em;
  border-radius: 50px;
  width: 80%;
  line-height: 2em;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  align-items: flex-end;
}
.com-animal-wrapper-2{
  position: relative;
  display: block;
  text-align: justify;
  padding: 2em;
  margin: 0 auto;
  border-radius: 50px;
  width: 100%;
  line-height: 2em;
  display: grid;
  grid-template-columns: 40% 40%;
  column-gap: 10%;
  margin: 0 auto;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;  
  background-color: var(--appPinkTransLight);
}

.com-pink{
  color: var(--appPinkMedium);
  font-weight: 600;
}
.home-animal-wrapper2, .com-animal-wrapper{
  border: 1px solid var(--appPinkMedium);
  color: var(--appPinkMedium);
}

.home-animal-title{
  color: var(--appPinkMedium);
  margin: 0 auto;
  width: 80%;
  margin-top: 2em;
}
.home-animal-text-first{ 
  font-size: larger;
  font-weight: 600;
  font-size: 1.6em;
  z-index:3;
  color: var(--appWhiteColor);
 
}
.home-animal-text-second, .home-animal-text-third, .com-animal-text-third{
  font-size: 1.2em;
  margin-top: 1em;
 
}
.home-animal-text-third, .com-animal-text-third{
  font-style: oblique;
  position: relative;
  z-index: 2;
}
.com-animal-text-third{
  margin-left: 1em;
}
.home-animal-img{
  border-radius: 50% 50% 0 0;
  border: 3px solid var(--appPinkMedium);
  height: 100%;
  width: inherit;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  -o-object-fit: cover;
     object-fit: cover; 
 z-index: 5;
}
.com-animal-img-wrapper{
  width: 130px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.home-animal-img-wrapper{
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.home-teaching-container{
  position: relative;
  margin: 0 auto;
 /*  background-color: var(--appWhiteColor); */
  background: linear-gradient(180deg, rgba(225,238,208,0) 0%, rgba(254, 252, 233, 0.5) 10%, rgba(225, 222, 197, 0.5) 80%, rgba(255,255,255,0) 100%);
}
.home-share-container{
  position: relative;
  color: var(--appDarkGreenColorPlain);
  background-color: var(--appWhiteColor);
  /* background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgb(255, 255, 255, 0) 20%,  rgb(255, 255, 255, 1) 72%,rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%); */
}
.home-teaching-wrapper{
  position: relative;
  display: grid;
  grid-template-columns: 55% 40%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  padding-top: 8em;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
  justify-items: center;

}
.home-share-wrapper{
  position: relative;
  display: grid;
  grid-template-columns: 40% 55%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  padding-top: 8em;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
  justify-items: center;
  background: linear-gradient(90deg, var(--appGreenColor) 0%, rgba(98, 172, 161, 0.9) 100%);
  perspective: 2000px;
  perspective-origin: center;
}
.home-teaching-image{
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  position: relative;
  z-index: 2;
}
.home-teaching-text-wrapper{
  position: relative;
  width: 100%;
  color: var(--appBrownFont);
  /* background-color: var(--appLightGreenColor); */
  font-weight: 600;
  line-height: 2em;
}
.home-share-text-wrapper{
 /*  background: linear-gradient(90deg, rgb(142, 251, 204) 0%, rgba(98, 172, 161, 0.6) 100%); */

 border-radius: 10px;

}
.home-share-title{
  position: relative;
 color: var(--appDarkGreenColorPlain);
 text-align: right;
 margin: 0 auto;
 width: 80%;
 margin-top: 1em;
}
.home-teaching-title{
 position: relative;
 color: var(--appMainColor);
 text-align: right;
 margin: 0 auto;
 width: 80%;
 margin-top: 1em;
}
.home-teaching-text{
  position: relative;
  margin: 0 auto;
  width: 80%;
  line-height: 2em;
}
.home-teaching-text-first{
  position: relative;
  text-align: right;
  font-weight: 600;
  font-size: larger;
  font-size: 1.5em;
  z-index:3;
}
.home-teaching-text-second{
  position: relative;
  text-align: right;
  font-weight: 500;
  color:#26271c;
  font-size: 1.2em;
  z-index:3;
  line-height: 2em;
}
.home-teaching-text-background{
  top:0;
  left: -20em;
  position: absolute;
  display: block;
  width: 70%;
  height: 90%;
  -o-object-fit: contain;
     object-fit: contain;
}

.presta-title-wrapper{
  margin: 0 auto;
  /* margin-top: 25em; */
  color: var(--appBlue);
  transition: color 0.5s linear;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  width: 100%;
}
.appearing-position{
  position: relative;
  display: block;
}
.presta-title-wrapper-with-background{
  margin: 0 auto;
  margin-top: 25em;
  color:rgb(254 252 252);
  font-weight: 600;
  transition: color 0.5s linear ;
 
}
.presta-subtitle-wrapper{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.presta-intro-container{
  position: relative;
  display: block;
  margin: 0 auto;
  background-color: var(--appWhiteColor);
  margin-top: 5em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
  width: 80%;
}
.presta-title-wrapper span{
  font-weight: 600;
  font-size: 1.5em;
  opacity: 0.8;
  transition: opacity 0.5s linear;
  line-height: 2em;
}
.presta-title-wrapper-with-background span{
  font-weight: 600;
  font-size: 1.5em;
  opacity: 1;
  transition: opacity 0.5s linear;
}
.presta-subtitle{
  font-weight: 600;
  font-size: 1.5em;
  opacity: 0.8;
  margin-bottom: 1em;
  margin-top: 1em;
}
/*HOME PRESTA*/
.home-presta-text{
  text-align: left;
  position: relative;
  margin: 0 auto;
  width: 80%;
  line-height: 2em;
  font-size: 1.2em;
  z-index:3;
}
.home-presta-text-background{
  top:0;
  right: 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 0 auto;
}
.home-presta-container{
  position: relative;
  padding: 1em; 
 /*  background: var(--appWhiteColor); */
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgb(255, 255, 255) 27%, rgb(255, 255, 255) 72%,rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
}
.home-presta-text-first{
  color: var(--appBlue);
  font-size: larger;
  font-weight: 600;
  font-size: 1.2em;
  z-index:3;
}
.home-presta-text-second{
  color: var(--appDarkBlue);
  line-height: 2em;
  font-size: 1em;
}

.home-presta-title{
  color: var(--appDarkBlue);
  text-align: left;
  width: 80%;
  margin: 0 auto;
}
.home-presta-wrapper{
  position: relative;
  display: grid;
  grid-template-columns: 40% 50%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  padding-top: 8em;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
  justify-items: center;
}
.home-presta-text-wrapper{
  position: relative;
  text-align: left;
}
h2.com-title{
  font-family: var(--appFfTitle);
  font-weight: 500;
  color: var(--appPinkMedium);
  font-size: 75px;
  margin: 0.25em;
  margin-top: 1em;
}
h2.presta-title{
  font-family: var(--appFfTitle);
  font-weight: 500;
  color: var(--appDarkBlue);
  font-size: 70px;
  margin: 0.25em;
  margin-top: 1em;
}

/*NEW HOME*/
h1.home-gold, h1.home-gold-intercepting{
  font-family : var(--appFfTitle);
  font-weight: 500;
  /* text-transform: uppercase; */
  /* background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%); */
 
 /*  background-clip: text; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  display: block;
  text-align: center;
  font-size: 100px;
  font-stretch: extra-expanded;
  margin-bottom: 0;
}
h1.presta-gold, h1.presta-gold-intercepting{
  font-family : var(--appFfTitle);
  font-weight: 500;
  color: var(--appDarkBlue);
  /* text-transform: uppercase; */
  /* background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%); */
 
 /*  background-clip: text; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  display: block;
  text-align: center;
  font-size: 75px;
  font-stretch: extra-expanded;
  margin-bottom: 0;
}
h1.home-gold{
  font-family : var(--appFfTitle);
  font-weight: 500;
  /* text-transform: uppercase; */
  /* background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%); */
  background: linear-gradient(360deg, var(--appDarkBlue), #27599bf8 80.71%);
  background-clip: text; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  text-align: center;
  font-stretch: extra-expanded;
  transition: linear-gradient 1s linear;
  margin-bottom: 0;
}
.h1.home-gold-intercepting{
  background-clip: text; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: linear-gradient 1s linear;
  margin-bottom: 0;
  background: linear-gradient(360deg, var(--appWhiteTransColor), #dae4f1f8 80.71%);
}
/* h1.home-gold-intercepting{
  font-family : var(--appCapsFont);
  text-transform: uppercase;
  background: linear-gradient(360deg, var(--appDarkBlue), #27599bf8 100%);
  background-clip: text; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  text-align: center;
  font-size: 3em;
  font-stretch: extra-expanded;
  transition: linear-gradient 1s linear;
} */
.home-intro-text-wrapper{
  position: relative;
  width: 100%;
  color: var(--appDarkBlue);
  /* background-color: var(--appLightGreenColor); */
  font-weight: 600;
  line-height: 2em;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  font-size: 1.2em;
  text-align: justify;
  /* background: linear-gradient(90deg, rgba(255,255,255,0.10696778711484589) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 87%, rgba(255,255,255,0) 95%, rgba(255,255,255,0) 100%); */
}
.home-intro-image{
  display: block;
  width: 90%;
  height: 90%;
  -o-object-fit: contain;
     object-fit: contain;
  position: relative;
  z-index: 2;
}
.home-intro-text{
  position: relative;
  height: 50%;
  width: 80%;
  margin: 0 auto;
  z-index: 3;
  line-height: 2em;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.home-intro-text-second{
  position: relative;
}
.home-intro-text-first{
  position: relative;
}
.home-intro-text-background{
  top:0;
  left: 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  transform: scaleX(-1);
  -o-object-fit: contain;
     object-fit: contain;
}
.com-intro-text-background{
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  width: auto;
  height: 100%;
  opacity: 0.25;
  object-fit: cover;
  border-radius: 30px;
}
.contact-title-background{
  top: 10%;
  right: 10%;
  position: absolute;
  display: block;
  width: 250px;
  transform: scaleX(-1) rotate(15deg);
  height: auto;
  opacity: 0.1;
  filter: grayscale() contrast(1.5);
  object-fit: contain;
 
}
.background-manager{
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;

}

.background-home-first{
  top: 40%;
  position: relative;
  display: block;
  width: 60%;
  height: 60%;
  margin: 0 auto;
  -o-object-fit: contain;
     object-fit: contain;
  opacity: 1;
  transition: opacity 0.5s linear;
  transition-delay: 2s;
}
.background-home-bckg{
  top: 40%;
  position: relative;
  display: block;
  opacity:0;
  transition: opacity 0.5s linear;
  transition-delay: 2s;
  width: 60%;
  height: 60%;
  margin: 0 auto;
  -o-object-fit: contain;
     object-fit: contain;
  
}
.background-price-first{
  position: absolute;
  display: block;
  width: 80%;
  height: 80%;
  margin: 0 auto;
  -o-object-fit: contain;
     object-fit: contain;
  bottom: 0;
  right: 0;
  transform: scaleX(-1);
}
.navlink-wrapper{
  text-align: left;
}
.background-home-second{
  top: 0;
  position: absolute;
  display: flex;
  width: 60%;
  height: 60%;
  opacity: 0.5;
  margin: 0 auto;
  transform: scaleX(-1);
  -o-object-fit: contain;
     object-fit: contain;
}
.home-intro-container{
  position: relative;
  /* background: linear-gradient(180deg, rgba(255,255,255,0.10696778711484589) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 87%,  rgba(255,255,255,0) 100%); */
 
  
}

.home-intro-title-wrapper{
  position: absolute;
  background-color: rgba(8, 8, 100, 0.5);
  width: 100%;
  height: 150px;
  top: 0;
  left: 0;
  z-index: 1;
  
}
.logo-intro{
  display: block;
  width: 150px;
  position: relative;
  margin: 0 auto;
  margin-top: 2em;
  margin-bottom: 2em;
}
.home-intro-wrapper{
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  /* background: linear-gradient(90deg, rgba(255,255,255,0.10696778711484589) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 87%, rgba(255,255,255,0) 95%, rgba(255,255,255,0) 100%); */
 
}

.home-trans-mask{
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-mask-image: url("../images/common/equilys_mask.png");
          mask-image: url("../images/common/equilys_mask.png");
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: contain;
          mask-size: contain; 
  background: rgb(237,238,174);
  background: radial-gradient(circle, rgba(237,238,174,0.5131302521008403) 0%, rgba(38,128,235,1) 100%);
  margin: 0 auto;
  margin-top: 2em;
  margin-bottom: 2em;
  
}

.homewrapper{
  margin: 0 auto;
  width: 100%;
  max-width: 100vw;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  overflow-y: hidden;
  overflow-x: hidden;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgb(255, 255, 255, 1) 20%,  rgb(255, 255, 255, 0) 72%,rgba(255,255,255,0) 90%, rgba(255,255,255,0) 100%);
  padding-top: 1em;
}
/*FIN NEw HOME*/
.compowrapper{
  margin: 0 auto;
  margin-top: 5em;
  width: 100%;
  max-width: 100vw;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  overflow-y: hidden;
  overflow-x: hidden;
 
}
.actu-wrapper{ 
  margin: 0 auto;
  width: 100%;
  max-width: 100vw;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: 2em;
}
.EnseignementWrapper{
  margin: 0 auto;
  margin-top: 2em;
  width: 100%;
  max-width: 100vw;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-bottom: 2em;
 
}
.pricewrapper{
 
  width: 100%;
  max-width: 100vw;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;

}
.background-presta-first{
  width: 100vw;
  top: 0;
  position: relative;
  display: relative;
  margin: 0 auto;  
  -o-object-fit: cover;  
     object-fit: cover;
  height: 450px;
}

.presta-h2{
  font-family: var(--appFtTitle);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  background: radial-gradient(circle, rgba(255, 234, 0, 0.971) 0%, var(--appDarkGold) 35%, var(--appGold) 100%);
  /* background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%); */
  color: var(--appDarkGold);
  background-clip: text; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content; 
  font-size: 50px;
 /*  border-bottom: 2px solid var(--appDarkBlue); */

 /*  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.presta-h2-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.presta-h2-content{
  width: 80%;
  margin: 0 auto;
}
.presta-h2-embeded{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  color: var(--appDarkGold);
  width: 100%;
  margin: 0 auto;
  margin-top: 2em;
  margin-bottom: 1em;
  padding: 0.5em;
  border: none;
  /* border-bottom: 2px solid var(--appDarkBlue);  */
 box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
}
.intro-presta-wrapper{
  margin-top: 2em;
  display: grid;
  grid-template-columns: 70% 30%;
  justify-content: center;
  align-items: center;
  justify-items: stretch;
  width: 90%;
  padding-top: 1em;
}
.image-mask{
  display: block;
  -o-object-fit: contain;
     object-fit: contain;
  height: inherit;
  width: inherit;
}

/* navbar */

.navbar-wrapper{
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  position: relative;
  width: 100%;
  height: var(--navHeight);
  max-width: 100vw;
  margin: 0;
  top: 0;
  left: 0;
  flex-direction: row;
}
.opened-navbar{
  display: block;
  position: absolute;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  /* min-height: 100px; */
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 6px 9px rgb(50 50 93 / 6%), 0 2px 5px rgb(0 0 0 / 8%);
  border-bottom: var(--appMainColor) 2px solid;
  border-top: var(--appMainColor) 2px solid;
  max-width: 100vw;
  margin: 0;
  top: 0;
  left: 0;
  transition: left 2s 1s;
  }

.navbar-container{
  position: fixed;
  top: 0;
  left: 0;
  display: inline-flex;
  width: 100vw;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;  
  z-index: 1000;
  transition: top 0.5s linear, background-color 0.5s linear;

}
.navbar-container-scroll{
  position: fixed;
  top: 0;
  left: 0;
  display: inline-flex;
  width: 100vw;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,1);
  z-index: 1000;
  transition: top 0.5s linear, background-color 0.5s linear;
}
.video-container{
transform: translateX(0px);
transition: transform 1s ease-out, opacity 1s ease-out;
-webkit-transition: transform 1s ease-out, opacity 1s ease-out;
opacity: 1;
margin: 0 auto;
/* margin-top: 2em; */
margin-bottom: 2em;
position: relative;
}
.video-container-onload{
transform: translateX(-200px);
opacity: 0;
transition: transform 1s ease-out, opacity 1s ease-out;
-webkit-transition: transform 1s ease-out, opacity 1s ease-out;
margin: 0 auto;
/* margin-top: 2em; */
margin-bottom: 2em;
position: relative;
}
.video-wrapper{
  position: relative;
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 25px;
  box-shadow: rgb(68 71 70 / 40%) 0px 6px 10px 0px;
}
.home-video-wrapper{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 30px;
  box-shadow: rgb(68 71 70 / 40%) 0px 6px 10px 0px;
 
  
}
.teaching-video-wrapper{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  box-shadow: rgb(68 71 70 / 40%) 0px 6px 10px 0px;
 
  
}
.presta-video-wrapper{
  position: relative;
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 10px;
   
  
}
.full-video{
  width: inherit;
  height: inherit;
  position: relative;
}
/* loading video / img */

.img-vidoe-loading{
  width: inherit;
  height: 100%;
  position: absolute;
  opacity: 1;
  z-index: 2;
  top: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s linear;
}
.home-img-vidoe-loading{
  width: inherit;
  height: 100%;
  position: absolute;
  opacity: 1;
  z-index: 2;
  top: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s linear;
  border-radius: 30px;
}
.teaching-img-vidoe-loading{
  width: inherit;
  height: 100%;
  position: absolute;
  opacity: 1;
  z-index: 2;
  top: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s linear;
 
}
.bubble-img-vidoe-loading{
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 1;
  z-index: 2;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s linear;
}
.img-video-loaded{
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 2;
  top: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s linear;
}
.home-img-video-loaded{
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 2;
  top: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s linear;
  border-radius: 30px;
}
.teaching-img-video-loaded{
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 2;
  top: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s linear;

}
.bubble-img-video-loaded{
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 2;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s linear;
}
.home-video-loaded{
  position: relative;
  margin: 0 auto;
  display: flex;
  width: inherit;
  height: inherit;
  opacity: 1;
  transition: opacity 1s linear;
  z-index: 2;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
 
}
.teaching-video-loaded{
  position: relative;
  margin: 0 auto;
  display: flex;
  width: inherit;
  height: inherit;
  opacity: 1;
  transition: opacity 1s linear;
  z-index: 2;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;  
 
}

.presta-img-video-loaded{
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 2;
  top: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s linear;
  border-radius: 30px;
}
.presta-img-vidoe-loading{
  width: inherit;
  height: 100%;
  position: absolute;
  opacity: 1;
  z-index: 2;
  top: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s linear;
  border-radius: 30px;
}
.presta-video-loading{
  position: relative;
  width: inherit;
  height: inherit;
  opacity: 0;
  transition: opacity 1s linear;
  z-index: -1;
  border-radius: 10px;
}
.presta-video-loaded{
  position: relative;
  margin: 0 auto;
  display: flex;
  width: inherit;
  height: inherit;
  opacity: 1;
  transition: opacity 1s linear;
  z-index: -1;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
 
}
.video-loaded{
  position: relative;
  margin: 0 auto;
  display: flex;
  width: inherit;
  height: inherit;
  opacity: 1;
  transition: opacity 1s linear;
  z-index: -1;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.bubble-video-loaded{
  position: relative;
  margin: 0 auto;
  display: flex;
  width: inherit;
  height: inherit;
  opacity: 1;
  transform: scale(1.8);
  transition: opacity 1s linear;
  z-index: -1;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.video-loading{
  position: relative;
  width: inherit;
  height: inherit;
  opacity: 0;
  transition: opacity 1s linear;
  z-index: -1;
}
.home-video-loading{
  position: relative;
  width: inherit;
  height: inherit;
  opacity: 0;
  transition: opacity 1s linear;
  z-index: -1;
  border-radius: 30px;
}
.teaching-video-loading{
  position: relative;
  width: inherit;
  height: inherit;
  opacity: 0;
  transition: opacity 1s linear;
  z-index: -1;
 
}
.bubble-video-loading{
  position: relative;
  width: inherit;
  height: inherit;
  opacity: 0;
  transition: opacity 1s linear;
  z-index: -1;
  transform: scale(1.8);
}
.presta-video{
 position: relative;
 width: 348px;
 height: 196px;
 -webkit-filter:hue-rotate(0.5);
         filter:hue-rotate(0.5);
}
.video-mask{
  position: absolute;
  width: 435px;
  height: 245px;
  z-index: 2;
  top: 0;
  left:0;
  background-color: rgba(10, 67, 73, 0.5);
} 
.presta-article{
  margin-top: 1.5em;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}
.badge-bottom-fake-wrapper{
  position: relative;
  width: auto;
  height: 100px;
  text-align: center;
  top: -3em;
  background: var(--appLightBlue);
  -webkit-clip-path: circle(45%);
          clip-path: circle(45%);
  
}
.badge-bottom-img-wrapper{
  position: relative;
  width: auto;
  height: 100px;
  text-align: center;
  top: -3em;
 
  
}
.block-badge-wrapper{
  position: relative;
  background-color: rgba(255,255,255,0.8);
  opacity: 1;
  height: 300px;
  min-height: -moz-fit-content;
  min-height: -webkit-fit-content;
  min-height: fit-content;
  margin: 0 auto;
  width: 90%;
  margin-bottom: 1.5em;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 0.7em;
}
.badge-bottom-img{
height: 100%;
width: 100%;
color: var(--appBlue);
border-radius: 50%;
border: 4px solid var(--appBlue);
}
.presta-img-wrapper{
  position: absolute;
  width: inherit;
  height: inherit;
  margin: 0 auto;
  top:0;
  left:0;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
 
}
.presta-img-wrapper-delay{
  position: absolute;
  width: inherit;
  height: inherit;
  margin: 0 auto;
  top:0;
  left:0;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
  transition: transform 0.8s linear, opacity 0.8s linear;
  transform: scale(0);
}
.presta-img-wrapper-delay.active{
  width: inherit;
  height: inherit;
  margin: 0 auto;
  transform: scale(1);
  opacity: 1;
  transition: transform 0.8s cubic-bezier(.42, 0, .58, 1), opacity 0.8s cubic-bezier(.42, 0, .58, 1);
}
.presta-img-wrapper.active{
  width: inherit;
  height: inherit;
  margin: 0 auto;
  -webkit-animation: 0.8s scalepop;
  animation: 0.8s scalepop; 
  
  opacity: 1;
}
.presta-img-wrapper:nth-child(2n){
  transition-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  
}
.presta-img-wrapper.active:nth-child(2n){
  transition-delay: 0.5s;  
  -webkit-animation-delay: 0.5s;  
          animation-delay: 0.5s;
  
  
}
.presta-img-wrapper.active:nth-child(3n){
  transition-delay: 0.3s;  
  -webkit-animation-delay: 0.3s;  
          animation-delay: 0.3s;

}

.presta-details{
  color: var(--appDarkBlue);
  display: flex;
  /* background: rgb(92,88,170);
  background: linear-gradient(0deg, rgba(92,88,170,0.5663515406162465) 0%, rgba(91,87,169,1) 17%, rgba(81,77,149,1) 46%, rgba(92,88,170,1) 80%, rgba(92,88,170,1) 100%); */
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0.5em;
  border-radius: 50px;
  border-bottom: 1px solid var(--appDarkBlue);
  border-right: 1px solid var(--appDarkBlue);
 

}
.presta-details-wrapper{
  line-height: 2em;
  text-align: justify;
  margin: 0 auto;
  display: flex;
  width: 80%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
}
.presta-img{
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin: 0 auto;
}
.presta-img:nth-child(2n){
  transform: translateY(-20px) scale(0.75);
}
.presta-img:nth-child(3n){
  transform: translateY(20px) scale(0.5);
}
.presta-img-container{
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  justify-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  position: relative;
  margin-top: 0.5em;
}

.booking-form{
  width: 80%;
  border-radius: 10px;
  margin: 0 auto;
  border: 1px solid var(--appDarkGold);
  margin-top: 5em;
}
h2{
  color: var(--appGreenColor);
}
.booking-wrapper{
  position: relative;
  display: block;
  margin: 0 auto;
  margin-top: 5em;
}
.hero-photo-actus, .hero-photo-home{
  position: relative;
  width: 100%;
  height: 80vh;
  object-fit: cover;
  transform: scaleX(-1);
}


.hero-photo{ 
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-width: 100vw;
  transform: scaleX(-1);
}
.hero-photo-com{
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-width: 100vw;
}

.com-hero-photo{
  position: relative;
  width: 100%;
  min-width: 100vw;
  height: 100vh;
  
}
.hero-container{
  width: 100%;
  max-width: 100vw;
  position: relative;

  
}
.com-dogs{
  width: 150px;
  height: 150px;
  position: absolute;
  top: 50%;
}
.com-effect, .com-effect-02, .com-static{
  position: absolute;
  z-index: 3;
  width: auto;
  height: 100%;
  top: 0;
  left: 0;
 
}
.com-effect-bckg, .com-effect-bckg02{
  position: absolute;
  z-index: 3;
  width: 100%;
  margin: 0 auto;
  height: auto;
  filter: grayscale();
  top: 0;
  left: 0;
 
}

.com-effect{
  -webkit-filter: hue-rotate(10);
          filter: hue-rotate(10);
  -webkit-animation: 35s ease-in infinite alternate birthAndDie;
          animation: 35s ease-in infinite alternate birthAndDie;
}
.com-effect-bckg, .com-effect-bckg02{
  -webkit-filter: hue-rotate(10);
  filter: hue-rotate(10);
-webkit-animation: 35s ease-in infinite alternate birthAndDieBckg;
  animation: 35s ease-in infinite alternate birthAndDieBckg;
}
.com-effect-02{
  -webkit-filter: hue-rotate(30);
          filter: hue-rotate(30);
  -webkit-animation: 35s ease-out infinite alternate birthAndDie02;
          animation: 35s ease-out infinite alternate birthAndDie02;
}
.com-cartes{
  position: relative;
  width: 50%;
  object-fit: cover;
  border-radius: 10px 10px 30px 10px;
  margin: 0 auto;
}
.com-ingrid{
  position: absolute;
  z-index: 5;
  width: 15%;
  height: auto;
  top: 5%;
  right: 10%;
  border-radius: 20% 2% 20% 2%;
  box-shadow: var(--appPinkTransLight) 0px 10px 20px;
}
.hero-enseignement-photo{
  width: 100%;
  min-width: 100vw;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
}
.logo-container{
  display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.menu-tel{
  display: none;
}

.logo-menu{
  display: block;
  position: relative;    
  height: var(--navHeight);
  width: auto;
  top: 0;
  right: 0;
}
.burger-cross{
  display: none;
 }
 .burger-cross:active{
  display: none;
 }
 .burger-cross-animated{
  display: none;
 
 }
.menu-tel-nopop{  
  position: relative;
  display: none;
  text-align: left;
  top: 0;
  left:0;
  cursor: pointer;
  z-index: 4;
  color: var(--appDarkGold);
}

.li-menu, .li-submenu{
  color: var(--fontMainColor);
  text-decoration: none;
  font-size: var(--mediumFontSize);
  padding: 0.5em;
  z-index: 1000;
}
.li-menu{
  line-height: 1.5em;
}
.li-submenu{
  line-height: 2.5em;
}
.li-menu:hover, .li-submenu:hover{
  transform: scaleY(1.2);
  -webkit-transform: scaleY(1.2);
  filter: brightness(2); 
  -webkit-filter: brightness(2);
  transition: transform 0.5s linear, -webkit-filter 0.5s linear;
  transition: transform 0.5s linear, filter 0.5s linear;
  transition: transform 0.5s linear, filter 0.5s linear, -webkit-filter 0.5s linear;
  -webkit-transition: transform 0.5s linear, filter 0.5s linear;
}
.li-menu-activ{
  display: block;
  color: var(--appDarkGold);
  text-decoration: none;
  font-size: var(--mediumFontSize);
  transform: scaleY(1.12);
  -webkit-transform: scaleY(1.15);
  transition: transform 2s color 2s;
  -webkit-transition: transform 2s color 2s;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
}
/*Carousel*/
.carousel-container{
  position: relative;
  width: 100%;
  /* height: 400px; */
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  height: auto;
 
 
}
.carousel-img-wrapper{
  -o-object-fit: cover;
     object-fit: cover;
  height: inherit;
  width: inherit;
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}
.carousel-position-group{
  width: var(--carouselImgWidth);
  position: relative;
  display: block;
  margin: 0 auto;
 /*  -webkit-clip-path: inset(1% 1% 1% 1%);
          clip-path: inset(1% 1% 1% 1%); */
}

.carousel-group{
  position: relative;
  width: 100%;
  display: block;
}
.carousel-effect{
  position: absolute;
  width: 100%;
  top: 0;
  height: var(--carouselImgHeight);
  background-image: linear-gradient(360deg, rgba(4 55 96 / 51%), rgba(255,0,0,0) 80.71%);
}
.appearing-carousel-group{
  position: absolute;
  top:0;
  height: 100%;
  width: 100vw;
  display: block;
  margin: 0 auto;
}
.carousel-wrapper{
 display: block;
 position: relative;
 margin: 0 auto;
 width: 100%;
}
.ref-div{
  position: absolute;
  height: var(--carouselImgHeight);
  width: var(--carouselImgWidth);
  margin: 0 auto;
  
}

 .appearing-carousel-container{ 
  height: 600px;
  width: 100%;
  position: relative;
 
}

.home-carousel-container{ 
  height: inherit;
  width: 100%;
  position: relative;
  object-fit: cover;
}
 


.appearing-carousel-stamp{
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  margin: 0 auto;
}
.appearing-stamp{
  position: absolute;
  opacity: 1;
  width: 100%;
  z-index: 5;
  height: fit-content;  
  transition: opacity 0.5s linear, top 0.5s linear;
  top: 80%;
  /*background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(2,0,36,0.5) 13%, rgba(108,176,97,0.5) 60%, rgba(108,176,97,0) 80%, rgba(108,176,97,0) 100%);
  -webkit-mask-image: url("../images/partage/mask.png");
  mask-image: url("../images/partage/mask.png"); 
  -webkit-mask-position: bottom center;
  mask-repeat: no-repeat;
  mask-position: bottom center;
  mask-size: 100%;
  mask-origin: content-box;    */
}
.hidden-stamp{
  position: absolute;
  left: 0;
  top: 100%;
  opacity: 0;
  width: 100%;
  height: 100px;
  transition: opacity 0.5s linear;
}
.stamp-wrapper{
  width: 70%;
  display: flex;
  margin: 0 auto;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}
.presta-container{
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  background-color: var(--appWhiteColor);
  margin-top: 5em;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;  */
  width: 100%;
  text-align: center;
}

.presta-details-container{
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  position: relative;
}


.appear-title-span{
  color: var(--appWhiteColor);
  font-weight: 600;
  font-family: var(--appFtTitle);
  font-size: 62px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
}

.animated-presentiel-title{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: calc(var(--carouselImgHeight)/2);
  font-size: 40px; 
  font-weight: 500;
  color: var(--appPinkLight);
  width: 100%;
  height: calc(var(--carouselImgHeight)/2);
  z-index: 5;
  -webkit-animation: 1.5s scalefront;
  animation: 1.5s scalefront;
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  
}
.presentiel-title{
  font-size: 40px; 
  color: var(--appPinkLight);
  font-weight: 500;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: calc(var(--carouselImgHeight)/2);
  width: 100%;
  height: calc(var(--carouselImgHeight)/2);
  z-index: 5;
  transition: opacity 0.5s ease-in-out, z-index 0.5s ease-in-out;
 
}
.presta-animated-h2{
  opacity: 0;
  transform: translateY(80px);
  transition: opacity 0.5s linear, transform 0.75s linear;
}
.presta-intro-wrapper{
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}
.presta-animated-h2.active{
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 0.5s linear, transform 0.75s linear;
}
.presentiel-no-title{
  font-size: 35px; 
  color: var(--appPinkLight);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: calc(var(--carouselImgHeight)/2);
  width: 100%;
  height: calc(var(--carouselImgHeight)/2);
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease-in-out, z-index 0.5s ease-in-out;
 
}


.animated-stamp{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: var(--stampLeft);
  top: calc(var(--carouselImgHeight)/2);
  background-color: var(--appTransColor);
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  width: var(--stampWidth);
  height: calc(var(--carouselImgHeight)/6);
  z-index: 5;
  -webkit-animation: 1.5s scalepoplight;
  animation: 1.5s scalepoplight;
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  border-radius: 50px;
}
.stamp{
  position: absolute;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: calc(var(--carouselImgHeight)/2);
  left: var(--stampLeft);
  background-color: var(--appTransColor);
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  width: var(--stampWidth);
  height: calc(var(--carouselImgHeight)/6);
  z-index: 5;
  transition: opacity 0.5s ease-in-out, z-index 0.5s ease-in-out;
}
.no-stamp{
  border-radius: 50px;
  left: var(--stampLeft);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: calc(var(--carouselImgHeight)/2);
  background-color: var(--appTransColor);
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  width: var(--stampWidth);
  height: calc(var(--carouselImgHeight)/6);
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease-in-out, z-index 0.5s ease-in-out;
}
.sliding-div{
  width: var(--carouselImgWidth);
  height: var(--carouselImgHeight);
  position: relative;
}


.carousel-img{
  height: var(--carouselImgHeight);
  width: var(--carouselImgWidth);
}
.visible-image{
  position: relative;
  height: var(--carouselImgHeight);
  width: var(--carouselImgWidth);
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  
}
.absolute-groups{
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: fit-content;
}
.appearing-visible-image{
  position: relative;
  width: 100%;
  height: auto;
  object-fit: cover;
  min-width: 100vw;
}
.invisible-image{
  position: relative;
  height: var(--carouselImgHeight);
  width: var(--carouselImgWidth);
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 0.5s ease-in-out;
}
/*Button Shiny*/
.btnshine-position{
  position: relative;

}
.btnshine-absolute{
  position: absolute;
  z-index: 10;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  top: 150px;
}

.btnshine-container {
  width: 100%;
  height: 100px;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signature-zone{
  width: 450px;
  height: 250px;
  border: 1px solid var(--appDarkGold);
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
}
.print-signature-zone{
  width: 450px;
  height: 150px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
}
.btnshine-center {
  width: 180px;
  height: 60px;
  position: absolute;
}
.obj-form-subtitle{
  text-align: left;
  margin-left: 2em;
}
.obj-print-subtitle{
  font-size: 12pt;
}
.ta-form-hidden{
  display: none;
}
.obj-printable-input{
  border: none;
  border-bottom: 1px solid var(--appBlue);
  margin: 0;
  background: transparent;
  margin-bottom: 10pt;
  width: 200pt;
  display: block;
  position: relative;
}
.small-obj-printable-input{
  border: none;
  border-bottom: 1px solid var(--appBlue);
  margin: 0;
  background: transparent;
  margin-bottom: 10pt;
  width: 100pt;
  display: block;
  position: relative;
}
.obj-printable-address{
 
}
.obj-pdfform-input{
  border: none;
  border-bottom: 1px solid var(--appBlue);
  margin: 0;
  line-height: 2em;
  background: transparent;
  margin-bottom: 2em;
  width: 200px;
  margin-left: 1em;
}
.obj-form-input, .ta-form-input, .obj-form-input-ar, .obj-form-select-ar{
    border: none;
    border-bottom: 1px solid var(--appBlue);
    margin: 0;
    line-height: 2em;
    background: transparent;
    margin-bottom: 2em;
    width: 80%;
    display: block;
    position: relative;
}
.signature-form-input{
  border: none;
  border-bottom: 1px solid var(--appBlue);
  margin: 0;
  line-height: 2em;
  background: transparent;
  margin-bottom: 2em;
  width: 50%;
  display: block;
  position: relative;
}
.level-txt{
  padding-left: 1em;
}
.mentions-footer-wrapper{
  z-index: 0;
}
.footer-wrapper{
  z-index: 0;
  margin-top: 2em;
}
.obj-form-input-ar, .obj-form-select-ar{
  text-align: right;
  margin-left: 2em;
}
.obj-print-select-ar{
  height: 11pt;
  width: 50%;
  font-size: larger;
  text-align: center;
}
.obj-form-select-ar{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1em;
  font-size: larger;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.signature-form-input, .obj-pdfform-input:focus,.obj-pdfform-input:active, .obj-pdfform-input:hover, .obj-form-select-ar:active, .obj-form-select-ar:hover, .obj-form-select-ar:focus, .obj-form-input-ar:focus,.obj-form-input:focus{
  outline: none;
}
.btnshine-div {
  width: 180px;
  height: 60px;
  position: relative;
  margin: 0 auto;
}
.btnshine-training-center {
  width: 180px;
  height: 60px;
  position: absolute;
}
.btnshine-training{
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 1px solid var(--appLightGold);
  outline: none;
  transition: 1s ease-in-out;
  font-family: 'Lato', sans-serif;
}
/*home-actu*/
.card-wrapper {
  height: 400px;
  margin-bottom: 50px;
  margin-top: 50px;
  perspective: 2000px;
  perspective-origin: top;
  position: relative;
  width: 90%;
 
}
.card-container {
  height: 400px;
  margin-bottom: 50px;
  margin-top: 50px;
  perspective: 2000px;
  perspective-origin: top;
  position: relative;
  width: 90%;
 
}
.open-card{
  color: #fff;
  background-color: var(--appWhiteColor);
  font-weight: 700;
  height: 100%;
  margin: 0 auto;
  position: relative;
  transform-style: preserve-3d;
  transition: transform .8s cubic-bezier(.86,0,.07,1);
  width: 100%;
  transform: rotateY(180deg);

}
.card {
  color: #fff;
  font-weight: 700;
  height: 100%;
  margin: 0 auto;
  position: relative;
  transform-style: preserve-3d;
  transition: transform .8s cubic-bezier(.86,0,.07,1);
  background-color: var(--appWhiteColor);
}
.card-front {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.card-back, .card-front {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 8px;
  cursor: pointer;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  width: 100%;

}
.card-back{
  background-color: var(--appWhiteColor);
  color: var(--appDarkBlue);
  border: 2px groove var(--appGreenColor);
 
  z-index: 10;
}

.card-back {
  position: relative;
  overflow-y: auto;
  align-items: center; 
  background: transparent;
  display: block;
  transform: rotateY(180deg);

}

.home-actu-animGroup{
  display: block;
  color: white;
  position: absolute;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100px;
  width: 100%;
  opacity: 1;
  top: 100%;
  z-index: 3;
  transform-origin: top;
  transform: scaleY(1);
  transition: transform 0.2s linear, top 0.2s linear;
  background-color: var(--appBlueTrans);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0 auto;
  flex-direction: column;
  margin-bottom: 1em;
  border-radius: 10px 10px 20px 20px;
}

.home-actu-hidden-group{
  position: absolute;
  display: block;
  height: 0;
  transform: scaleY(0);
  transform-origin: top;
  width: 80%;
  opacity: 0;
  top:100%;
  

}
.home-actu-block-title, .home-actu-block-subtitle{
  z-index: 3;
  color: var(--appGreenColor);
  line-height: 2.5em; 
  
}
.home-actu-block-subtitle{
  
  background-color: var(--appBlackTrans);
  filter: brightness(1.2);
  text-transform: capitalize;
}
.home-actu-block-title{
  font-variant: small-caps;
  background-color: var(--appWhiteTransColor);
  border-bottom: 1px solid var(--appLightGold);
}
div.detail-row{
  margin: 0 auto;
 line-height: 1.5em;
 padding: 0.5em;
}
.baby-horse{
  color: var(--appDarkGold);
  font-size: 35px; 
  -webkit-animation: 0.8s ease-in-out 0s infinite alternate-reverse rotate-baby-horse;
  animation: 0.8s ease-in-out 0s infinite alternate-reverse rotate-baby-horse; 
}
.detail-row{
  margin: 0 auto;
  color: black;
  font-weight: 500;
}
.home-actu-compo-textblock{
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 auto;
  margin-top: 0.5em;
}
.home-actu-compo-title{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: stretch;
  cursor: pointer;
  margin: 0 auto;
  line-height: 2em;
}

.home-actu-photo-group{
  position: relative;
  display: block;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  margin: 0 auto;
  margin-left: 3em;
 
}

.home-actu-compo-img{
  position: absolute;
  z-index: 2;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -webkit-filter: grayscale();
          filter: grayscale();
  box-shadow: rgba(68, 71, 70, 0.4) 0px 6px 10px 0px;

}
.main-bckg-actu-compo-img{
  position: absolute;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  opacity: 0.08;

  -webkit-filter: grayscale() brightness(1.8) contrast(0.8);

          filter: grayscale() brightness(1.8) contrast(0.8);
  background-color: var(--appWhiteColor);
  transform: scaleX(-1);
  top:0;
  left: 0;
  z-index: 1;
}
.bckg-actu-compo-img{
  position: fixed;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  opacity: 0.15;
  -webkit-filter: grayscale() brightness(1.8) contrast(0.8);
          filter: grayscale() brightness(1.8) contrast(0.8);
  background-color: var(--appWhiteColor);
  box-shadow: rgba(68, 71, 70, 0.4) 0px 6px 10px 0px;
  top:0;
  left: 0;
}
.home-actu-icon{
  position: absolute;
  margin: 0 auto;
  z-index: 5;
  top: -32px;
  left: -25px;
  width: 80px;
  height: 80px;
  color: var(--appDarkGold);
  transform: rotateZ(-10deg);
}
.home-actu-compo-articles-wrapper{
  grid-column-gap: 10%;
  grid-row-gap: 10%;
  align-items: center;
  -moz-column-gap: 10%;
       column-gap: 10%;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
  height: 100%;
  justify-content: center;
  justify-items: center;
  margin: 2em auto 4em;
  row-gap: 10%;
  width: 90%;
}
.home-actu-compo-article{
 
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 10em;

}
.home-actu-compo-date{
  width: inherit;
  height: inherit;
  position: relative;
  margin-top: 0.75em;
}
.btnshine-training svg{
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: var(--appDarkGold);
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
}

.btnshine-training:hover {
  transition: 1s ease-in-out;
 
}

.btnshine-training:hover svg {
  stroke-dashoffset: -480;
}

.btnshine-training span {
  color: var(--appDarkGold);
  font-size: 18px;
  font-weight: 100;
  text-transform: capitalize;
}
/**/
.btnshine-bckg{
background: var(--appBlackTrans);
}
.submenu-wrapper{
  position: absolute;
  display: none;
  top: 4em;
  
  width: 0;
  margin: 0 auto;
  height: 0;
  opacity: 0;
  background-color: var(--appWhiteColor);
  border-bottom: 1px solid var(--appDarkGold);
  transition: height 0.5s linear;
  z-index: 0;
}
.submenu-wrapper.active{
  display: flex;
  flex-direction: column;
 opacity: 1;
 height: fit-content;
 width: 180px;
 transition: height 0.5s linear;
}
.btnshine, .btnshine-bckg {
  width: 180px;
  height: 60px;
  cursor: pointer;
  font-variant: small-caps;
  border: 1px solid var(--appLightGold);
  outline: none;
  transition: 1s ease-in-out;
  font-family: 'Lato', sans-serif;
}
.btnshine{
  background: transparent;
}

.btnshine svg, .btnshine-bckg svg{
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: var(--appDarkGold);
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
}

.btnshine:hover, .btnshine-bckg:hover {
  transition: 1s ease-in-out;
  background: transparent;
}

.btnshine:hover svg, .btnshine-bckg:hover svg {
  stroke-dashoffset: -480;
}

.btnshine span, .btnshine-bckg span {
  color: var(--appDarkGold);
  font-size: 18px;
  font-weight: 100;
}
.witness-container{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  margin: 0 auto;
  margin-left: 10%;
  margin-right: 10%;
  -moz-column-gap: 50px;
       column-gap: 50px;
  line-height: 1.5em;
  text-align: justify;
}
.witnessData{
  margin-top: 1em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  line-height: 2em;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
}
.witnessData:nth-child(2n){
  justify-content: flex-start;

}
.witness-data-container{
  width: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
}

.witnessData-signature{
  font-style: oblique;
  margin-top: 0.5em;
  color: var(--appBlue);
  font-weight: 600;
}
.hero-title-witness h2{
  text-align: center;
  font-size: 3em;
  background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero-title-witness h1{
  text-align: center;
  font-family: var(--appFfTitle);
  font-size: var(--h1Hero);
}
.hero-title-witness{
  position: absolute;
  display: flex;
  z-index: 4;
  top: inherit;
  left: inherit;
  margin-top: inherit;
  width: inherit;
  height: inherit;
  /* background-color: rgba(60, 135, 234, 0.51); */
  background-image: linear-gradient(360deg, rgba(4 55 96 / 51%), rgba(255,0,0,0) 80.71%);
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
/*PROP 01*/
.p01-header-wrapper{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;

}
.p01-article-row{
  position: relative;
  display: flex;
  grid-template-columns: 45% 45%;
  width: 100%;
  margin: 0 auto;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 10px;
  
  margin-bottom: 150px;
}
.p01-article-row-reverse{
  position: relative;
  display: flex;
  grid-template-columns: 45% 45%;
  width: 100%;
  margin: 0 auto;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  border-radius: 10px;
  
  margin-bottom: 150px;
}

.p01-img-mask{
  position: absolute;
  top: 0;
}
.p01-img-wrapper{
 position: relative;
}
.p01-masked-img{
  -webkit-clip-path: url("../svg/hero_mask.svg");
          clip-path: url("../svg/hero_mask.svg");
}
.svg-fit{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.p01-image-article-outcard{ 
  -o-object-fit: cover;
     object-fit: cover;
  transform: scale(1) translateX(0);
  transition: transform 1s ease-out;
  position: relative;
 
/*   perspective: 2000px;
  perspective-origin: left; */
 
}
.p01-video-article-outcard{ 
  width: 450px;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  transform: scale(1) translateX(0);
  transition: transform 1s ease-out;
  position: relative;
  perspective: 2000px;
  perspective-origin: left;
 
}
.p01-image-article-outcard:nth-child(2){
  transform:  scale(0.8) translateX(50px) translateY(1px);
 
}
.p01-image-article-outcard:nth-child(3){
  transform: scale(0.8) translateX(-26px) translateY(66px);
 
}
.p01-image-article-outcard:nth-child(4){
  transform: scale(0.9) translateX(-55px) translateY(8px);

}
.p01-image-article-outcard:nth-child(5){
  transform:  scale(0.8) translateX(-60px) translateY(50px);

}
.p01-image-article-outcard-onload{
  transform: scale(0) translateX(-100px);
  -o-object-fit: cover;
     object-fit: cover;
  transition: transform 1s linear;
 
}
.p01-video-article-outcard-onload{
  transform: scale(0) translateX(-100px);
  width: 450px;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  transition: transform 1s linear;
 
}
.p01-article-img-prop{
  
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 25px;
  box-shadow: rgba(68, 71, 70, 0.4) 0px 6px 10px 0px;
}

.p01-article-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  margin-top: 150px;
  
}
.article-images{
  margin-left: 50px;
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  -moz-column-gap: 20px;
       column-gap: 20px;
  perspective: 2000px;
  perspective-origin: left;
}
.p01-article-header{
  display: flex;
  text-align: center;
  position: relative;
  border: none;
  border-top: solid 2px var(--appCaution);
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.p01-card-container{
  border-bottom: 1px solid var(--appDarkGreenColor);
  border-left: 1px solid var(--appDarkGreenColor);
  border-radius: 20px 5px 20px 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
 
}
.input-title{
  font-size: 20px;
  font-weight: 600;
  color: var(--appDarkBlue);
  margin: 0 auto;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
}
.print-autorisation{
  font-style: italic;
  color: var(--appDarkGold);
}
.input-title-print{
  font-size: 12pt;
  font-weight: 600;
  color: var(--appDarkBlue);
  margin: 0 auto;
  margin-top: 10pt;
  margin-bottom: 10pt;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
}
.p01-card-container-reverse{
  border-bottom: 1px solid var(--appDarkGreenColor);
  border-right: 1px solid var(--appDarkGreenColor);
  border-radius: 20px 5px 20px 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.p01-img{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  z-index: 2;
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
  box-shadow: var(--appLightGreenColor) 0px 0px 0px 3px, var(--appCaution) 0px 0px 0px 6px;
}
.presta-intro-subtitle{
  font-weight: 500;
  font-size: 2em;
}
.p01-card-title{
  margin: 0 auto;
  font-family: var(--appFfTitle);
  font-weight: 500;
  font-size: 3em;
  color: var(--appGreenColor);
  margin-bottom: 50px;
  margin-top: 100px;
  position: absolute;
}
/*CGV*/
.cgv-citation{
  font-style:oblique;
}
.cgv-article-p{
  display: block;
  position: relative;
  line-height: 2em;
}
.cgv-article-details-p{
  display: block;
  position: relative;
  margin: 0 auto;
  margin-left: 2em;
}
.cgv-article-container{
  width: 90%;
  margin: 0 auto;
  
}
.cgv-article-wrapper-just{
  text-align: justify;
  line-height: 1.8em;
}
.cgv-article-wrapper{
  text-align: left;
}
.cgv-article-wrapper:nth-child(2n){
  text-align: right;
}
.cgv-article-title{
  text-transform: uppercase;
}
.nav-header-wrapper{
  width: 25%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  height: var(--navHeight);
}
.h2-pink{
  margin: 0 auto;
  font-family: var(--appFfTitle);
  font-weight: 500;
  font-size: 55px;
  color: var(--appPinkMedium);
  margin-bottom: 50px;
  margin-top: 100px;
  position: relative;
}
.h2-green{
  font-size: 45px;
}
.h2-green-teaching{
  font-size: 60px;
}
.h2-green, .h2-green-teaching{
  margin: 0 auto;
  font-family: var(--appFtTitle);
  font-weight: 500;
 
  color: var(--appGreenColor);
  margin-bottom: 50px;
  margin-top: 100px;
  position: relative;
}

.span-green{
  margin: 0 auto;
  font-family: var(--appFfTitle);
  font-weight: 500;
  font-size: 3em;
  color: var(--appGreenColor);
  position: relative;
}

.p01-card-wrapper{
  width: var(--cardWidth);
  height: var(--cardHeight);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  display: block;
  margin: 0 auto;

 
}
@media (min-width: 912px) and (max-width: 1450px){
  :root{
  --prezImageWidth: 150px;
  --prezImageHeight: 150px;
  --homeImageWidth: 450px;
  --homeImageHeight: 300px;
  --articleHeight: 350px;
  --articleWidth: 300px;
  --prezArticleImageWidth: 200px;
  --prezArticleImageHeight:200px;
  --h1FontSize: 1.2em;
  --articleFontSize: 0.9em;
  --h1Hero: 3em;
  --h2Hero: 2em;
  --stampWidth: 8em;
  --stampLeft: -30px;  
  }
  
  .hero-photo-actus, .hero-photo-home{
    height: 60vh;
  }

  .link-pdf{
    margin: 0 auto;
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
    position: relative;
   }
   .h2-contact{
    font-size: 30px;
  }

  .home-presta-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 5%;
    padding-top: 8em;
    align-items: center;
    justify-content: space-around;
    align-content: center;
    justify-items: stretch;
  }

  .article-images{
    margin-top: 1em;
  }
  .home-teaching-image{
    display: block;
    -o-object-fit: cover;
       object-fit: cover;
    position: relative;
    z-index: 2;
  }
  .gradient{
    height: 5em;
  }
  .main-actu-intro{
    font-family: var(--appFfTitle);
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding-left: 20px;
  }
  .wrapper-actualities{
    width: 100%;
    margin: 0 auto;
  }
  .main-actu-compo-article {
    position: relative;
    display: grid;
    grid-template-columns: 40% 55%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 50px;
    justify-items: center;
}
  .transition{
    height: 200px;
    width: 100%;
    /* background: linear-gradient( rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 45%, rgba(255,255,255,0) 75%, rgba(255,255,255,0.25) 100%); */
   background: transparent;
  }
  .submenu-wrapper{
    position: absolute;
    display: none;
    top: 4em;    
    width: 0;
    margin: 0 auto;
    height: 0;
    opacity: 0;
    background-color: var(--appWhiteColor);
    border-bottom: 1px solid var(--appDarkGold);
    transition: height 0.5s linear;
    z-index: 0;
  }
  .submenu-wrapper.active{
    display: flex;
    flex-direction: column;
   opacity: 1;
   height: fit-content;
   margin: 0 auto;
   width: 110px;
   transition: height 0.5s linear;
  }
  .h2-nav{
    font-size: 12px;
    
  }
  .appearing-stamp{
    top: 70%;
  }
  .partage-article-container{
    margin-top: 5em;
  }
 
  .home-trans-mask{
    margin: 0 auto;
    margin-top:4em;
  }
  .appearing-carousel-container{
    height: 450px;
  }
  .signature-mentions-title {
    font-size: 50px;
  }
  .presta-intro-container{
    width: 90%;
  }
  .presta-container{
    width: 90%;
  }
  .main-actu-compo-container{
    padding-top: 1em;
    margin-top: 0;
    top: -1em;
  }
  h1.home-gold, h1.home-gold-intercepting{
    font-size: 80px;
    margin: 0 auto;
    margin-top: 1em;
  }
  .main-actu-compo-date{
    flex-direction: column;
  }
  
  .price-col-price{
    font-size: 2em;
  }
  
  .p01-image-article-outcard:nth-child(2){
    transform: scale(1) translateX(-34px) translateY(40px);

  }
  .p01-image-article-outcard:nth-child(3){
    transform: scale(0.9) translateX(-52px) translateY(0);

  }
  .p01-image-article-outcard:nth-child(5){
    transform: scale(1) translateX(8px) translateY(36px);

  }
  .p01-image-article-outcard:nth-child(4){
    transform: scale(0.9) translateX(-94px) translateY(39px);
  }
  .witness-container{
    display: flex;
    margin: 0 auto;
    margin-left: 10%;
    margin-right: 10%;
    line-height: 1.5em;
    text-align: justify;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: stretch;
  }
  .hero-text-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: flex-start;
  
  }
  .home-teaching-text-background{
    top:0;
    left: 0;
    position: absolute;
    display: block;
    width: 70%;
    height: 90%;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .title-span{
    font-size: 30px;
  }
  .logo-footer{
    width: 50px;
    height: 50px;
  }
  
  
  .home-title-mask{
    width: calc(var(--homeImageWidth)*2.3/3);
    height: calc(var(--homeImageHeight)*1/3);
    background-color: var(--appCaution);
    position: relative;
    top: calc(var(--homeImageHeight)/2);
   
  }
  .home-title-image{
    width: calc(var(--homeImageWidth)*2/3);
    height: calc(var(--homeImageHeight)*2/3);
    position: relative;
    -o-object-fit: cover;
       object-fit: cover;
 
  }
  .li-menu, .li-submenu{
    color: var(--fontMainColor);
    text-decoration: none;
    font-size: var(--mediumFontSize);
    padding: 0.1em;
    font-size: 11px;
   
  }
  .li-menu-activ{
    font-size: 12px;
  }
}
/* @media  (max-width: 810px){ */
  @media (min-width: 810px) and (max-width: 911px){
  :root{
    --prezImageWidth: 150px;
    --prezImageHeight: 150px;
    --homeImageWidth: 450px;
    --homeImageHeight: 300px;
    --h1FontSize: 1.1em;
    --articleHeight: 350px;
    --articleWidth: 300px;
    --prezArticleImageWidth: 200px;
    --prezArticleImageHeight:200px;
    --articleFontSize: 0.9em;
    --h1Hero: 3em;
    --h2Hero: 3em;
    --stampWidth: 8em;
    --stampLeft: -30px;
    }
    .price-separation{
      margin-top: 0;
      text-align: left;
      padding-left: 0.25em;
    }
    .presta-h2-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-content: center;
      align-items: center;
      flex-wrap: nowrap;
    }
    .img-presta-title{
      height: 250px;
    }
    .presta-title-wrapper {
      margin: 0 auto;
      /* margin-top: 25em; */
      color: var(--appBlue);
      transition: color 0.5s linear;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      width: 100%;
  }
    h1.presta-gold{
      font-size: 35px;
      padding:0.25em;
    }
    .com-animal-download-zone{
      display: flex;
      flex-direction: column;
    }
    .home-actu-compo-articles-wrapper{
      display: block;
      height: 100%;
      margin: 0 auto;
      width: 90%;
    }
    .hero-photo-actus, .hero-photo-home{
      height: 35vh;
    }
    .presta-h2{
      font-family: var(--appFtTitle);
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-around;
      align-items: center;
      background: radial-gradient(circle, rgba(255, 234, 0, 0.971) 0%, var(--appDarkGold) 35%, var(--appGold) 100%);
      /* background: linear-gradient(360deg, #eabc3cac, rgba(255, 234, 0, 0.971) 80.71%); */
      color: var(--appDarkGold);
      background-clip: text; 
      -webkit-background-clip: text;
      -webkit-text-fill-color:  rgba(233, 214, 6, 0.971);
      width: fit-content;      
      border: none;
      font-size: 50px;
      font-weight: 500;
    }
    .li-menu-activ{
      font-size: 11px;
    }
    .com-container{
      font-size: 14px;
      line-height: 2em;
    }
    .actu-wrapper{
      margin-top: 4em;
    }
    .compowrapper{
      font-size: 14px;
      line-height: 2em;
    }
    .link-pdf{
      margin-top: 0em;
    }
    .nav-header-wrapper{
      width: 30%;
    }
    .card-wrapper {
      height: 200px;
      margin-bottom: 3em;
      margin-top: 2em;
      position: relative;
  
    }
    .home-actu-date {
      font-feature-settings: "smcp";
      background-color: var(--appBlackTrans);
      border: 2px groove var(--appGreenColor);
      border-radius: 10px;
      color: #7fa58e;
      color: var(--appWhiteColor);
      font-size: smaller;
      font-variant: small-caps;
      height: 60px;
      margin: 0 auto;
      padding: 1em;
      position: absolute;
      /* right: 150px; */
      left: 15px;
      top: 35%;
      margin: 0 auto;
      z-index: 2;
      display: flex;
      width: 80%;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
  }
  .home-actu-date-open{
    display: none;
  }
    .home-trans-mask{
      margin: 0 auto;
      margin-top: 4em;
    }
    .submenu-wrapper{
      position: absolute;
      display: none;
      top: 4em;    
      width: 0;
      margin: 0 auto;
      height: 0;
      opacity: 0;
      background-color: var(--appWhiteColor);
      border-bottom: 1px solid var(--appDarkGold);
      transition: height 0.5s linear;
      z-index: 0;
    }
    
    .partage-article{
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: 0 auto;
    }
    .partage-article-container {
      font-size: 14px;
      line-height: 2em;
      margin-top: 5em;
  }
    .appearing-carousel-container{
      height: 180px;
    }
    .com-animal-wrapper4{
      font-size: 14px;
    }
    .com-animal-formules-txt{
      font-size: 14px;
    }
   
    
h1{
  font-size: 1.8em;
}

.hero-com{
  position: absolute;
  display: flex;
  z-index: 4;
  top: inherit;
  left: inherit;
  margin-top: inherit;
  width: inherit;
  height: 100%;
  /* background-color: rgba(60, 135, 234, 0.51); */
  background-image: linear-gradient(360deg, rgba(4 55 96 / 51%), rgba(255,0,0,0) 80.71%);
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
}
.module-img{
  height: 250px;
}
.signature-mentions-title {
  font-size: 50px;
}
    .main-actu-compo-container{
      padding-top: 0em;
    }
    h1.home-gold, h1.home-gold-intercepting{
      font-size: 80px;
      margin: 0 auto;
      margin-top: 0.5em;
      }
    .main-actu-compo-article{
      display: flex;
      flex-direction: column-reverse;
      
    }
   
    .main-actu-compo-date{
      flex-direction: column;
    }
   
    .price-col-price{
      font-size: 2em;
    }
    .eb-wrapper{
      align-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      position: relative;
      width: 100%;
      
    }

    h2{font-size: 1.6em;}
    .h2-contact{
      font-size: 24px;
    }
    .p01-image-article-outcard:nth-child(2){
      transform: scale(1) translateX(-34px) translateY(67px);

    }
    .p01-image-article-outcard:nth-child(3){
      transform: scale(0.9) translateX(-52px) translateY(0);
  
    }
    .p01-image-article-outcard:nth-child(5){
      transform: scale(1) translateX(8px) translateY(36px);
 
    }
    .witness-container{
      display: flex;
      margin: 0 auto;
      margin-left: 10%;
      margin-right: 10%;
      line-height: 1.5em;
      text-align: justify;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: stretch;
    }
    .background-price-first{
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      -o-object-fit: contain;
         object-fit: contain;
    }
    .background-price-first{
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      -o-object-fit: contain;
         object-fit: contain;
    }
    .hero-text-wrapper{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-end;
      align-items: center;
    
    }
    .logo-menu{
      display: block;
      position: relative;    
      height: 60px;
      width: auto;
      top: 0;
      right: 0;
    }
    .h2-nav{
      font-size: 12px;    
    }
 
  
   
    .home-teaching-text-background{
      top:0;
      left: 0;
      position: absolute;
      display: block;
      width: 70%;
      height: 90%;
      -o-object-fit: contain;
         object-fit: contain;
    }
    .home-teaching-image{
      display: block;
      width: 80%;
      height: 80%;
      -o-object-fit: contain;
         object-fit: contain;
      position: relative;
      z-index: 2;
    }
      /*NEW HOME*/
      .home-intro-image{
        display: block;
      width: 80%;
      height: 80%;
      margin: 0 auto;
      -o-object-fit: contain;
         object-fit: contain;
      position: relative;
      z-index: 2;
      }
      .home-intro-title-wrapper{
        height: 0;
      }
      .home-intro-wrapper{
        display: flex;
        flex-direction: column-reverse;
        padding: 0;       
        background: linear-gradient(90deg, rgba(255,255,255,0.10696778711484589) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 87%, rgba(255,255,255,0) 95%, rgba(255,255,255,0) 100%);
      }
      .home-trans-mask{
        height: 100%;
        width: 100%;
      }
      .background-home-first{
  
        position: relative;
        display: relative;
        width: 100%;
        height: 100%;

        margin: 0 auto;
        -o-object-fit: contain;
           object-fit: contain;
      }

    .title-span{
      font-size: 30px;
    }
    .logo-footer{
      width: 40px;
      height: 40px;
    }
  
  .home-title-mask{
    width: calc(var(--homeImageWidth)*2.3/3);
    height: calc(var(--homeImageHeight)*1/3);
    background-color: var(--appCaution);
    position: relative;
    top: calc(var(--homeImageHeight)/2);
   
  }
  .home-title-image{
    width: var(--homeImageWidth);
    height: var(--homeImageHeight);
    position: relative;
    -o-object-fit: cover;
       object-fit: cover;

  }
  
  .hero-text h1, .hero-com h1{
    margin: 10px;
  }
  .hero-text h2, .hero-com h2{
    margin: 10px;
  }
.li-menu, .li-submenu{
  color: var(--fontMainColor);
  text-decoration: none;
  font-size: var(--mediumFontSize);
  padding: 0.5em;
  font-size: 9px;
}
}
@media  (min-width: 375px) and (max-width: 809px){
/* @media (min-width: 811px) and (max-width: 1086px){  */
  :root{
    --prezImageWidth: 150px;
    --prezImageHeight: 150px;
    --homeImageWidth: 450px;
    --homeImageHeight: 300px;
    --articleHeight: 350px;
    --articleWidth: 250px;
    --prezArticleImageWidth: 200px;
    --prezArticleImageHeight:200px;
    --h1FontSize: 1.2em;
    --articleFontSize: 0.9em;
    --h1Hero: 2.5em;
    --h2Hero: 1.5em;
    --mediumFontSize: 0.8em;
    --p01ImageWidth: 200px;
    --p01ImageHeight: 100px;

    --carouselImgHeight: 200px;
    --stampWidth: 8em;
    --stampLeft: -30px;
    --navHeight: 2em;
    }
    .price-separation{
      margin-top: 0;
      text-align: left;
      padding-left: 0.25em;
    }
    .presta-h2-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-content: center;
      align-items: center;
      flex-wrap: nowrap;
    }
    .img-presta-title{
      height: 250px;
    }
    .img-presta-title{
      height: 250px;
    }
    .presta-title-wrapper {
      margin: 0 auto;
      /* margin-top: 25em; */
      color: var(--appBlue);
      transition: color 0.5s linear;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      width: 100%;
  }
    h1.presta-gold{
      font-size: 35px;
      padding:0.25em;
    }
    .com-animal-download-zone{
      display: flex;
      flex-direction: column;
    }
    .hero-photo-actus{
      height: 32vh;
    }
    .hero-photo-home{
      height: 30vh;
    }
    .com-container{
      font-size: 14px;
      line-height: 2em;
    }
    .h2-contact{
      font-size: 24px;
    }
    .link-pdf{
      margin-top: 0em;
    }
    .nav-header-wrapper{
      width: 85%;
    }
    .card-wrapper {
      height: 200px;
      margin-bottom: 3em;
      margin-top: 2em;
      position: relative;
    }
    .home-actu-date-open{
      display: none;
    }
    .home-actu-date {
      font-feature-settings: "smcp";
      background-color: var(--appBlackTrans);
      border: 2px groove var(--appGreenColor);
      border-radius: 10px;
      color: #7fa58e;
      color: var(--appWhiteColor);
      font-size: smaller;
      font-variant: small-caps;
      height: 60px;
      margin: 0 auto;
      padding: 1em;
      position: absolute;
      /* right: 150px; */
      left: 15px;
      top: 35%;
      margin: 0 auto;
      z-index: 2;
      display: flex;
      width: 80%;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
  }
    .mentions-container{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: space-around;
      justify-content: space-around;
      align-items: center;
    }
    .hero-price-title{
      position: absolute;
      display: flex;
      z-index: 5;
      font-family: var(--appFfTitle);
      font-size: 50px;
      font-weight: 500;
      height: 50%;
      width: 100%;
      margin: 0 auto;
      color: var(--appWhiteColor);
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-end;
      align-content: flex-end;
     
    }
    .submenu-wrapper{
      position: relative;
      top: 0;      
      display: flex;
      flex-direction: column;
      width: 180px;
      margin: 0 auto;
      height: 0;
      opacity: 0;
      background-color: var(--appWhiteColor);
      border-bottom: 1px solid var(--appDarkGold);
      transition: height 0.5s linear;
      z-index: 0;
    }
    .h2-pink{
      font-size: 45px;
    }
    .simple-center-wrapper{
      line-height: 1em;
    }
    h2.com-title{
      font-size: 50px;     
      margin: 0.25em;
      margin-top: 1em;
      line-height: 1em;
    }
    .com-animal-separation, .price-separation, .com-animal-formules-separation{
      height: 120px;
    }
    .svg-mask{
      width: 200px;
      height: 200px;
      mask-size: 200px;
    }
  
    .com-animal-wrapper5{
      flex-direction: column;
      font-size: 12px;
    }
    .partage-article-title{
      font-size: 35px;
    }
    .partage-article{
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: 0 auto;
    }
    .partage-article-container {
      margin-top: 5em;
      line-height: 2em;
  }
    .appearing-stamp{
      top: 70%;
    }
    .stable-title{
      font-size: 55px;
     
    }
    .appearing-carousel-container{
      height: 150px;
    }
    .appear-title-span{
      font-size: 25px;
    }
  
    .df-r-c {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      flex-wrap: nowrap;
    }

    .com-animal-separation, .com-animal-formules-separation {
      font-size: 20px;
    }
    .price-separation{
      font-size: 25px;
      text-align: left;
      padding-left: 0.25em;
    }
    .img-communication-wrapper{
      flex-direction: column;
    }
    .com-animal-wrapper4{
      font-size: 14px;
    }
    .com-animal-formules-txt{
      font-size: 14px;
    }
    .com-animal-wrapper3{
      font-size: 14px;
    }
    .com-animal-formules-container{
      display: flex;
      flex-direction: column;
      column-gap: 2%;
      text-align: justify;
      line-height: 2em;
      width: 95%;
      height: 100%;     
    }
    .com-animal-wrapper-2{
      position: relative;
      display: block;  
      text-align: justify;
      padding: 2em;
      margin: 0 auto;
      border-radius: 50px;
      width: 80%;
      line-height: 2em;
      display: flex;
     flex-direction: column;
     margin-bottom: 1.5em;
    }
    .animal-img-device {
      width: 100%;
      height: auto;
  }
  .com-intro-text-background {
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: auto;
    opacity: 0.25;
    object-fit: cover;
    border-radius: 30px;
}
    .com-animal-wrapper{
      position: relative;
      padding-top: 8em;
      text-align: justify;
      padding: 2em;
      margin: 0 auto;
      margin-top: 2em;
      border-radius: 50px;
      width: 80%;
      line-height: 2em;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-content: center;
      justify-content: space-evenly;
      align-items: flex-end;
    }
    .home-intro-text-anim{
      z-index: -1;
    }
    .home-intro-text-image{
      z-index: -1;
    }
    h1{
      font-size: 1.8em;
    }
    h1.hero-com-title{
      font-size: 60px;
    }
    h2.hero-com-title{
      font-size: 30px;
      margin: 0;
    }
    .home-animal-img-wrapper{
      width: 60%;
    }
    .home-animal-anim{
      position: absolute;
      display:none;
    }
    .home-animal-hidden{
      position: absolute;
      height: 200px;
      width: 3px; 
      margin: 0 auto;  
      background-color: var(--appPinkMedium);
      transform-origin: top;
      transform: scaleY(0);
      transition: transform 1.5s linear;
    
    }
    .com-hero{
      width: 100%;
      height: auto;
      max-height: 100vh;

    }
    .home-animal-container{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    .module-img{
      height: 250px;
    }
    .signature-mentions-title {
      font-size: 50px;
    }
    h1{
      font-size: 1.8em;
    }
    .button-wrapper, .activ-button-wrapper{
      font-size: 15px;
    }
    .drawing-zone{
      width: 100%;
      margin: 0 auto;
     }
    .accept-zone{
      flex-direction: column;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
    
    }
    .civil-wrapper{
      flex-direction: column;
    }

    .pdf-logo-equilys{
      width: 75px;
      height: 75px;      
    }
   
    .signatures-modes-container{
      width: 100%;
      height: 100%;
    }
   
    .main-actu-compo-container{
      padding-top: 0em;
    }
    .main-actu-main-block {
      height: 160px;
      width: 80%;
    }
    h1.home-gold, h1.home-gold-intercepting{
      font-size: 60px;
      margin: 0 auto;
      }
    .main-actu-compo-article{
      display: flex;
      flex-direction: column-reverse;
      
    }
    .main-actu-compo-img, .main-actu-compo-img-right{
      border-radius: 10px 10px 10px 10px;
      
    }
    .main-actu-compo-date{
      flex-direction: column;
    }
   
   .home-actu-compo-articles-wrapper{
      display: block;
      height: 100%;
      margin: 0 auto;
      width: 90%;
    }
    .price-col-price{
      font-size: 2em;
    }
    .eb-wrapper{
      align-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      position: relative;
      width: 100%;
      
    }
    h2{font-size: 1em;}
    .witness-container{
      display: flex;
      margin: 0 auto;
      margin-left: 10%;
      margin-right: 10%;
      line-height: 1.5em;
      text-align: justify;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: stretch;
    }
    .presta-img{     
      height: 150px;
      width: 150px;    
    }
    
    .presta-details{
      font-size: 14px;
    }
    .presta-details-container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
  }
    .presta-title-wrapper span{
      font-weight: 600;
      font-size: 1em;
      opacity: 0.8;
    }
    .presta-title-wrapper-with-background span{
      font-weight: 600;
      font-size: 1em;
      opacity: 0.8;
    }
    .presta-subtitle{
      font-weight: 500;
      font-size: 1.4em;
  
    }
    
    

    .intro-price-wrapper{
      display: block;
    }
    .gradient{
      height: 5em;
    }
   
    .hero-text-wrapper{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-end;
      align-items: center;
    
    }
   
    
  
    .home-video-wrapper{
      
      position: relative;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 1.5em;
      border-radius: 30px;
      box-shadow: rgb(68 71 70 / 40%) 0px 6px 10px 0px;
  
      
    }
    .teaching-video-wrapper{
      
      position: relative;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin: 0 auto;      
      box-shadow: rgb(68 71 70 / 40%) 0px 6px 10px 0px;
  
      
    }
    .presta-video-wrapper{      
      position: relative;
      width: 80%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 1.5em;
      border-radius: 10px;
     
      
      
    }
    .home-teaching-text-background{
      top:0;
      left: 0;
      position: absolute;
      display: block;
      width: 70%;
      height: 90%;
      -o-object-fit: contain;
         object-fit: contain;
    }
    .home-teaching-text-first, .home-animal-text-first{
      font-size: 1.2em;
    }
    .home-teaching-text-second, .home-animal-text-second, .home-animal-text-third, .com-animal-text-third{
      font-size: 1em;
    }
    .home-presta-text-first{
      font-size: 1em;
    }
    .home-presta-text-second{
      font-size: 0.8em;
    }
    .home-intro-text-wrapper{
      font-size: 1em;
    }
   
   
    .title-effect{
      -o-object-fit: contain;
         object-fit: contain;
      width: 100%;
    }
    .transition{
      height: 100px;
      width: 100%;
      /* background: linear-gradient( rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 45%, rgba(255,255,255,0) 75%, rgba(255,255,255,0.25) 100%); */
     background: transparent;
    }
    /*ALAUNE*/
    .training-details{
      transform: scale(0.8);
    }
    .training-title{ 
      font-weight: 500;
      font-variant: small-caps;
      color: var(--appDarkGold);
      font-size: 0.8em;  
    }
    .btnshine-training-center {
      width: 180px;
      height: 60px;
      position: absolute;
      transform: scale(0.7);
    }
    .training-image{
      width: 100%;
      height: 100%;
      margin: 0 auto;
      -o-object-fit: cover;
         object-fit: cover;
      display: block;
      position: relative;
    }
    /*HOME PRESTA 900*/
    .home-presta-wrapper{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-content: center;
      justify-items: center;
      padding:0;
    }
    /*HOME TEACHING 900*/
  
    .home-teaching-wrapper {
      padding: 0em;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      justify-items: center;
      flex-direction: column-reverse;
      flex-wrap: nowrap;
     
    }
    .home-share-wrapper {
      padding: 0em;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      justify-items: center;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .home-teaching-image{
      display: block;
      width: 80%;
      height: 80%;
      -o-object-fit: contain;
         object-fit: contain;
      position: relative;
      z-index: 2;
    }
    /*NEW HOME*/

    .home-intro-image{
      display: block;
    width: 80%;
    height: 80%;
    margin: 0 auto;
    -o-object-fit: contain;
       object-fit: contain;
    position: relative;
    z-index: 2;
    }
    .home-intro-title-wrapper{
      height: 0;
    }
 
    .home-intro-wrapper{
      display: flex;
      flex-direction: column-reverse;
      padding: 0;
      background: linear-gradient(90deg, rgba(255,255,255,0.10696778711484589) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 87%, rgba(255,255,255,0) 95%, rgba(255,255,255,0) 100%);
    }
    .home-trans-mask{
      height: 180px;
      width: 100%;
      margin: 0 auto;
      margin-top: 2em;
    }
    .background-home-first{
   
      position: relative;
      display: relative;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      -o-object-fit: contain;
         object-fit: contain;
    }
    /**/
    .alaune-container{
      display: block;
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 3em;
      
    }
    
 
    
    .main-actu-compo-articles-wrapper{
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 90%;
      align-items: center;
      justify-items: center;
      justify-content: center;
      align-content: center;
      grid-template-rows: auto;
      margin-bottom: 3em;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
     
    }
    .main-actu-block-title, .main-actu-block-subtitle{
      font-size: 1.2em;
    }
    .main-actu-compo-textblock{
      font-size: 1em;
    }
    .wrapper-actualities{
      position: relative;
      display: block;
      width: 90%;
      margin: 0 auto;
   
    }
    .main-actu-compo-title2{
      font-size:50px;
    }
    .p01-video-article-outcard{ 
      width: 300px;
      height: auto;
      -o-object-fit: cover;
         object-fit: cover;
      transform: scale(1) translateX(0);
      transition: transform 1s ease-out;
      position: relative;
      perspective: 2000px;
      perspective-origin: left;
     
    }
    .price-phone-wrapper{
      display: flex;
      flex-direction: row;
    }
    .price-phone-wrapper:nth-child(2n){
      display: flex;
      flex-direction: row-reverse;
    }

    .price-img-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .price-fixed-background {
      position: relative;
      z-index: -1;
      display: flex;
      margin: 0 auto;
      width: 100%;
      height: inherit;
      background-image: url("../images/common/priceBackground.jpg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-attachment: fixed;
      background-position-x: center;
      background-position-y: center;
    }
   
  
  
    .p01-card-container-reverse{
      border: none;
    }
    .p01-card-container{
      border: none;
    }
    .enseignement-article-wrapper:nth-child(1){
      margin-top: 0;
    }
    .enseignement-text{
      width: 96%;
      margin: 0 auto;
    }
    .h2-green{
      font-size: 30px;
      margin-top: 50px;
    }
    .h2-green-teaching{
      font-size: 40px;
      margin-top: 50px;
    }
    .title-span{
      font-size: 20px;
    }
    .article-images{
      margin: 0 auto;
      position: relative;
      width: 97%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(110px, 2fr));
     
    }
    .p01-image-article-outcard:nth-child(2){
      transform: scale(1) translateX(-34px) translateY(67px);

    }
    .p01-image-article-outcard:nth-child(3){
      transform: scale(0.9) translateX(-52px) translateY(0);
  
    }
    .p01-image-article-outcard:nth-child(5){
      transform: scale(1) translateX(8px) translateY(36px);
 
    }
   /*  .p01-image-article-outcard:nth-child(4){
      transform: translateX(-6px) translateY(70px);   

    } */
    .p01-image-article-outcard:nth-child(4){
      transform: scale(0.8) translateX(-6px) translateY(70px);
    }
    .text-article{
      align-items: flex-start;
    }
    .p01-article-row{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      
    }
    .p01-article-row-reverse{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
   
    h1.gold{
    font-size: 1.5em;
    }

    

    .enseignement-article-container {
      display: block;
      width: 80%;
      text-align: justify;
      height: -moz-fit-content;
      height: -webkit-fit-content;
      height: fit-content;
      min-height: 100%;
  }
    .intro-presta-wrapper{
      margin-top: 2em;
      display: block;
      margin: 0 auto;
      width: 100%;
      justify-content: center;
      align-items: center;
      justify-items: stretch;
      line-height: 2em;
    }
    .logo-footer{
      width: 60px;
      height: 60px;
      padding: 1em;
    }

   .separate{
    display: none;
   }
 
    .sign-form-container{
      display: block;
      position: relative;
      width: 90%;
      margin: 0 auto;
      
    }
 
    .booking-form{
      width: 80%;
    }
    .presta-h2{
     padding:0;
     font-size: 35px;
    }
    .presta-h2-embeded{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-around;
      align-items: center;
      color: var(--appDarkGold);
      width: 100%;
      margin: 0 auto;
      margin-top: 2em;
      margin-bottom: 1em;
      padding: 0.5em;
      border: none;
      border-bottom: 2px solid var(--appDarkBlue);
      -webkit-filter: drop-shadow(0px 3px 10px #8b8b94);
              filter: drop-shadow(0px 3px 10px #8b8b94);
     /*  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    }
    .contact-form-container{      
      position: relative;  
      flex-direction: column;   
      display: flex;
      width: 100%;
      margin: 0 auto;
    }
    .article-card-wrapper-right{
      transform: translateX(-5em);
    }
    .equilys-plume{
      transform: scale(0.8);
    }
    .hero-photo, .com-hero-photo{
      width: 100%;
      height: auto;
    }
    .prez-article-title-image-right{
      width: inherit;
      height: inherit;
      position: absolute;
      -o-object-fit: cover;
         object-fit: cover;
      z-index: 5;
      top: calc(var(--articleHeight)*1/6);
      right: calc(var(--articleWidth)/-1.5);
    }
    .article-prez-wrapper-right{
      padding-right: 0;
    
    }
    .article-card-wrapper:nth-child(2){
      top: 60px;
      left: calc(var(--prezArticleImageWidth)/-4);
    }
    .home-article-wrapper{
      display: grid;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      grid-template-columns: none;
      grid-template-rows: repeat( auto-fit, minmax(calc(var(--articleWidth)), 1fr) );
      width: 80%;
      margin: 0 auto;
      margin-left: calc(var(--prezArticleImageWidth)/2);
    }
  .home-title-mask{
    width: calc(var(--homeImageWidth));
    height: calc(var(--homeImageHeight)*1/6);
    background-color: var(--appCaution);
    position: absolute;
    top: calc(var(--homeImageHeight)/2);
   
  }
  .home-title-image{
    width: calc(var(--homeImageWidth)*1/2);
    height: calc(var(--homeImageHeight)*1/2);
    position: relative;
    -o-object-fit: cover;
       object-fit: cover;
 
  }
  .hero-text h2{
   
    font-size: 1em;
    color: var(--appDarkBlue);
  }
  .hero-text h2{
    text-align: center;
  }
  .hero-com h2{
    text-align: right;
  }
  .hero-text{
    position: absolute;
    display: flex;
    z-index: 4;
    top: inherit;
    left: inherit;
    margin-top: inherit;
    width: inherit;
    height: inherit;
    /* background-color: rgba(60, 135, 234, 0.51); */
    background-image: linear-gradient(360deg, rgba(4 55 96 / 51%), rgba(255,0,0,0) 80.71%);
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .hero-com{
    position: absolute;
    display: flex;
    z-index: 4;
    top: inherit;
    left: inherit;
    margin-top: inherit;
    width: inherit;
   
    /* background-color: rgba(60, 135, 234, 0.51); */
    background-image: linear-gradient(360deg, rgba(4 55 96 / 51%), rgba(255,0,0,0) 80.71%);
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  h1.hero-title{
    color: var(--appWhiteColor);
    font-size: var(--h1Hero);
    font-weight: 200;
    margin: 0 auto;
    position: relative;
  }
 
h2.hero-title{
  font-size: var(--h2Hero);
}
  .burger-cross:active{
    display: none;
   }
  
  .burger-cross-animated{
    color: var(--appGreenColor);
    display: block;
    margin-left: 85%;
    height: 2em;
    width: 2em;
    -webkit-animation: 0.5s ease-in-out 0s 3 alternate rotate;
            animation: 0.5s ease-in-out 0s 3 alternate rotate; 
  
  }
 
  .navbar-container-scroll{
    position: fixed;
    background-color: var(--navBckgColor);
    top: 0;
    left: 0;
    margin: 0;
    display: inline-flex;
    width: 100vw;
    height: var(--telNavHeight);
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom: var(--appMainColor) 2px solid;
    transition : top 0.5s linear, -webkit-filter 0.5s linear;
  }
  .navbar-container{
    position: fixed;
    /* background-color: var(--navBckgColor); */
   /*  top: var(--navHeight); */
    left: 0;
    margin: 0;
    display: inline-flex;
    width: 100vw;
    height: var(--telNavHeight);
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom: var(--appMainColor) 2px solid;
    transform-origin: top left;
    transition: top 0.5s linear, -webkit-filter 0.5s linear;
    transition: top 0.5s linear, filter 0.5s linear;
    transition: top 0.5s linear, filter 0.5s linear, -webkit-filter 0.5s linear;
    -webkit-transition: top 0.5s linear, filter 0.5s linear;
  }

  .logo-menu{
    display: block;
    position: absolute;    
    height: 4em;
    width: auto;
    top: 0;
    right: 0;

  }
 
  .li-menu, .li-submenu{
    display: block;
    color: var(--fontMainColor);
    text-decoration: none;
    font-size: var(--mediumFontSize);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 1000;
  }
  .li-menu-closed{
    display: none;
    color: var(--fontMainColor);
    text-decoration: none;
    font-size: var(--mediumFontSize);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 1.5em;

  }
  .li-menu-activ{
    display: block;
    color:var(--fontMedColor);
    text-decoration: none;
    font-size: var(--mediumFontSize);
    transform: scaleX(1.1);
    -webkit-transform: scaleX(1.1);
    transition: transform 2s color 2s;
    -webkit-transition: transform 2s color 2s;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1.5em;
    z-index: 1000;
   
  }
  .opened-navbar{
    display: block;
    position: absolute;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 6px 9px rgb(50 50 93 / 6%), 0 2px 5px rgb(0 0 0 / 8%);
    border-bottom: var(--appMainColor) 2px solid;
    border-top: var(--appMainColor) 2px solid;
    max-width: 100vw;
    margin:0;
    top: 0;
    left: 0; 
    transition: left 1s;
    padding-left: 0;
  }
  .navbar-wrapper{
    display: block;
    position: absolute;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 6px 9px rgb(50 50 93 / 6%), 0 2px 5px rgb(0 0 0 / 8%);
    border-bottom: var(--appMainColor) 1px solid;
    border-top: var(--appMainColor) 2px solid;
    max-width: 100vw;
    margin: 0;
    top: 0;
    left: -50em;
    transition: left 2s 1s;
    
  }
  .menu-tel{  
    position: absolute;
    display: block;
    text-align: left;
    top: 0;
    left:0;
    width: auto;
    cursor: pointer;
    z-index: 4;
    height: var(--navHeight);
    width: auto;
    transform-origin: top left;
    animation: 1.08s ease-in-out 0s infinite alternate-reverse pop; 
  }
  .menu-tel-nopop{  
    position: absolute;
    display: block;
    text-align: left;
    top:0;
    /* top: calc(var(--navHeight)*2); */
    left:0;
    width: auto;
    cursor: pointer;
    z-index: 4;
    height: var(--navHeight);
    width: auto;
    transform-origin: top left;
    transition: top 0.5s linear, -webkit-filter 0.5s linear;
    transition: top 0.5s linear, filter 0.5s linear;
    transition: top 0.5s linear, filter 0.5s linear, -webkit-filter 0.5s linear;
    -webkit-transition: top 0.5s linear, filter 0.5s linear;
  }
  .menu-tel-nopop:hover{
    -webkit-filter: brightness(1.2);
    filter: brightness(1.2);
    transform-origin: center;
    transform: scale(1.08);
    transition: transform 1s linear;
    cursor: pointer;
  }
  
  @-webkit-keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
 
  
 

  @-webkit-keyframes pop{
    0% {
    
      transform: scale(1);
    }  
    
    100%{
      transform: scale(1.05);
      -webkit-filter: brightness(1.2);
              filter: brightness(1.2);
    }
  }

  @keyframes pop{
    0% {
    
      transform: scale(1);
    }  
    
    100%{
      transform: scale(1.05);
      -webkit-filter: brightness(1.2);
              filter: brightness(1.2);
    }
  }
  
  @-webkit-keyframes birth{
    0% {    
      transform: scale(0);
    }  
    
    100%{
     transform: scale(1);
    }
  }
  
  @keyframes birth{
    0% {    
      transform: scale(0);
    }  
    
    100%{
     transform: scale(1);
    }
  }
  .burger-cross{
    margin-left: 85%;
    height: 2em;
    width: 2em; 
    display: block;
    color: var(--appGreenColor);
  }
  .burger-cross:hover{
    display: block;
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
    transform-origin: center;
    cursor: pointer;
  }
  
  
  .menu-tel:hover{
    display: block;
    -webkit-filter: brightness(1.5) contrast(0.5);
            filter: brightness(1.5) contrast(0.5);
    transition: -webkit-filter 1s;
    transition: filter 1s;
    transition: filter 1s, -webkit-filter 1s;
    -webkit-transition: filter 1s;
  }  
}
@media  (max-width: 375px){
/* @media (min-width: 811px) and (max-width: 1086px){  */
  :root{
    --prezImageWidth: 150px;
    --prezImageHeight: 150px;
    --homeImageWidth: 450px;
    --homeImageHeight: 300px;
    --articleHeight: 350px;
    --articleWidth: 250px;
    --prezArticleImageWidth: 200px;
    --prezArticleImageHeight:200px;
    --h1FontSize: 1.2em;
    --articleFontSize: 0.9em;
    --h1Hero: 2.5em;
    --h2Hero: 1.5em;
    --mediumFontSize: 0.8em;
    --p01ImageWidth: 200px;
    --p01ImageHeight: 100px;
   
    --carouselImgHeight: 200px;
    --stampWidth: 8em;
    --stampLeft: -30px;
    --navHeight: 2em;    
    }

    .price-separation{
      margin-top: 0;
      text-align: left;
      padding-left: 0.25em;
    }
    .presta-h2-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-content: center;
      align-items: center;
      flex-wrap: nowrap;
    }
    h1.presta-gold{
      font-size: 35px;
      padding:0.25em;
    }
    .img-presta-title{
      height: 250px;
    }
    .presta-title-wrapper {
      margin: 0 auto;
      /* margin-top: 25em; */
      color: var(--appBlue);
      transition: color 0.5s linear;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      width: 100%;
  }
    h1.presta-gold{
      font-size: 35px;
      padding:0.25em;
    }
    .com-animal-download-zone{
      display: flex;
      flex-direction: column;
    }
    .home-actu-compo-articles-wrapper{
      display: block;
      height: 100%;
      margin: 0 auto;
      width: 90%;
    }
    .hero-photo-actus{
      height: 34vh;
    }
    .hero-photo-home{
      height: 30vh;
    }
    .simple-center-wrapper{
      line-height: 1em;
    }
    .com-container{
      font-size: 14px;
      line-height: 2em;
    }
    .h2-contact{
      font-size: 24px;
    }
    .link-pdf{
      margin-top: 0em;
    }
    .presta-details{
      font-size: 14px;
    }
    .nav-header-wrapper{
      width: 85%;
    }
    .card-wrapper {
      height: 200px;
      margin-bottom: 3em;
      margin-top: 2em;
      position: relative;
    }
    .home-actu-date-open{
      display: none;
    }
    .home-actu-date {
      font-feature-settings: "smcp";
      background-color: var(--appBlackTrans);
      border: 2px groove var(--appGreenColor);
      border-radius: 10px;
      color: #7fa58e;
      color: var(--appWhiteColor);
      font-size: smaller;
      font-variant: small-caps;
      height: 60px;
      margin: 0 auto;
      padding: 1em;
      position: absolute;
      /* right: 150px; */
      left: 15px;
      top: 35%;
      margin: 0 auto;
      z-index: 2;
      display: flex;
      width: 80%;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
  }
    .mentions-container{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: space-around;
      justify-content: space-around;
      align-items: center;
    }
    .hero-price-title{
      position: absolute;
      display: flex;
      z-index: 5;
      font-family: var(--appFfTitle);
      font-size: 50px;
      font-weight: 500;
      height: 50%;
      width: 100%;
      margin: 0 auto;
      color: var(--appWhiteColor);
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-end;
      align-content: flex-end;
     
    }
    .submenu-wrapper{
      position: relative;
      top: 0;      
      display: flex;
      flex-direction: column;
      width: 180px;
      margin: 0 auto;
      height: 0;
      opacity: 0;
      background-color: var(--appWhiteColor);
      border-bottom: 1px solid var(--appDarkGold);
      transition: height 0.5s linear;
      z-index: 0;
    }
    .h2-pink{
      font-size: 45px;
    }
    h2.com-title{
      font-size: 50px;
      margin: 0.25em;
    }
    .com-animal-separation, .price-separation, .com-animal-formules-separation{
      height: 100px;
    }
    .svg-mask{
      width: 150px;
      height: 150px;
      mask-size: 150px;
    }
   
    .h2-green{
      font-size: 30px;
      margin-top: 50px;
    }
    .h2-green-teaching{
      font-size: 40px;
      margin-top: 50px;
    }
    .com-animal-wrapper5{
      flex-direction: column;
      font-size: 14px;
    }
    .partage-article-title{
      font-size: 35px;
    }
    .partage-article{
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: 0 auto;
    }
    .partage-article-container {    
      font-size: 14px;
      margin-top: 5em;
      line-height: 2.5em;
  }
  
    .stable-title{
      font-size: 40px;
      top: 40%;
    }
    .appearing-carousel-container{
      height: 150px;
    }
    .appearing-stamp{
      top: 70%;
    }
    .appear-title-span{
      font-size: 25px;
    }
 
    .df-r-c {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      flex-wrap: nowrap;
    }
    .com-animal-separation,.com-animal-formules-separation {
      font-size: 20px;
    }
    .price-separation{
      font-size: 25px;
      text-align: left;
      padding-left: 0.25em;
    }
    .img-communication-wrapper{
      flex-direction: column;
    }
    .com-animal-wrapper4{
      font-size: 14px;
    }
    .com-animal-formules-txt{
      font-size: 14px;
    }
    .com-animal-formules-container{
      display: flex;
      flex-direction: column;
      column-gap: 2%;
      text-align: justify;
      line-height: 2em;
      width: 95%;
      height: 100%;     
    }
    .com-animal-wrapper3{
      font-size: 14px;
    }
    .com-animal-wrapper-2{
      position: relative;
      display: block;  
      text-align: justify;
      padding: 2em;
      margin: 0 auto;
      border-radius: 50px;
      width: 80%;
      line-height: 2em;
      display: flex;
     flex-direction: column;
     margin-bottom: 1.5em;
    }
    .animal-img-device {
      width: 100%;
      height: auto;
  }
  .com-intro-text-background {
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: auto;
    opacity: 0.25;
    object-fit: cover;
    border-radius: 30px;
}
    .com-animal-wrapper{
      position: relative;
      padding-top: 8em;
      text-align: justify;
      padding: 2em;
      margin: 0 auto;
      margin-top: 2em;
      border-radius: 50px;
      width: 80%;
      line-height: 2em;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-content: center;
      justify-content: space-evenly;
      align-items: flex-end;
    }
    .home-intro-text-anim{
      z-index: -1;
    }
    .home-intro-text-image{
      z-index: -1;
    }
    h1{
      font-size: 1.8em;
    }
    h1.hero-com-title{
      font-size: 60px;
    }
    h2.hero-com-title{
      font-size: 30px;
      margin: 0;
    }
    .home-animal-img-wrapper{
      width: 60%;
    }
    .home-animal-anim{
     display: none;

    }
    .home-animal-hidden{
      position: absolute;
      height: 200px;
      width: 3px; 
      margin: 0 auto;  
      background-color: var(--appPinkMedium);
      transform-origin: top;
      transform: scaleY(0);
      transition: transform 1.5s linear;
    
    }
    .home-animal-container{
      display: flex;
      flex-direction: column-reverse;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    .module-img{
      height: 250px;
    }
    .signature-mentions-title {
      font-size: 50px;
    }
    .button-wrapper, .activ-button-wrapper{
      font-size: 15px;
    }
    .drawing-zone{
      width: 100%;
      margin: 0 auto;
     }
    .accept-zone{
      flex-direction: column;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
    }
    .pdf-logo-equilys{
      width: 75px;
      height: 75px;      
    }
    .civil-wrapper{
      flex-direction: column;
    }

    .signatures-modes-container{
      width: 100%;
      height: 100%;
    }
    .main-actu-compo-container{
      padding-top: 0em;
    }
    .main-actu-main-block {
      height: 160px;
      width: 80%;
    }
    h1.home-gold, h1.home-gold-intercepting{
      font-size: 60px;
      margin: 0 auto;
      }
    .main-actu-compo-article{
      display: flex;
      flex-direction: column-reverse;
      
    }
  
    .main-actu-compo-img, .main-actu-compo-img-right{
      border-radius: 10px 10px 10px 10px;
    }
    .main-actu-compo-date{
      flex-direction: column;
    }
 
    
    .price-col-price{
      font-size: 2em;
    }
    .eb-wrapper{
      align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    width: 100%;
      
    }
    h2{font-size: 1em;}
    .witness-container{
      display: flex;
      margin: 0 auto;
      margin-left: 10%;
      margin-right: 10%;
      line-height: 1.5em;
      text-align: justify;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: stretch;
    }
    .presta-img{     
      height: 150px;
      width: 150px;    
    }
   
    
    .presta-details-container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
  }
    .presta-title-wrapper span{
      font-weight: 600;
      font-size: 1em;
      opacity: 0.8;
    }
    .presta-title-wrapper-with-background span{
      font-weight: 600;
      font-size: 1em;
      opacity: 0.8;
    }
    .presta-subtitle{
      font-weight: 500;
      font-size: 1.4em;
  
    }
    
    

    .intro-price-wrapper{
      display: block;
    }
    .gradient{
      height: 5em;
    }
   
    .hero-text-wrapper{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-end;
      align-items: center;
    
    }
   
    
  
    .home-video-wrapper{
      
      position: relative;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 1.5em;
      border-radius: 30px;
      box-shadow: rgb(68 71 70 / 40%) 0px 6px 10px 0px;
  
      
    }
    .teaching-video-wrapper{
      
      position: relative;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin: 0 auto;      
      box-shadow: rgb(68 71 70 / 40%) 0px 6px 10px 0px;
  
      
    }
    .presta-video-wrapper{      
      position: relative;
      width: 80%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 1.5em;
      border-radius: 10px;
     
      
      
    }
    .home-teaching-text-background{
      top:0;
      left: 0;
      position: absolute;
      display: block;
      width: 70%;
      height: 90%;
      -o-object-fit: contain;
         object-fit: contain;
    }
    .home-teaching-text-first, .home-animal-text-first{
      font-size: 1.2em;
    }
    .home-teaching-text-second, .home-animal-text-second, .home-animal-text-third{
      font-size: 1em;
    }
    .com-animal-text-third{
      font-size: 14px;
    }
    .home-presta-text-first{
      font-size: 1em;
    }
    .home-presta-text-second{
      font-size: 0.8em;
    }
    .home-intro-text-wrapper{
      font-size: 1em;
    }
    
   
    .title-effect{
      -o-object-fit: contain;
         object-fit: contain;
      width: 100%;
    }
    .transition{
      height: 100px;
      width: 100%;
      /* background: linear-gradient( rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 45%, rgba(255,255,255,0) 75%, rgba(255,255,255,0.25) 100%); */
     background: transparent;
    }
    /*ALAUNE*/
    .training-details{
      transform: scale(0.8);
    }
    .training-title{ 
      font-weight: 500;
      font-variant: small-caps;
      color: var(--appDarkGold);
      font-size: 0.8em;  
    }
    .btnshine-training-center {
      width: 180px;
      height: 60px;
      position: absolute;
      transform: scale(0.7);
    }
    .training-image{
      width: 100%;
      height: 100%;
      margin: 0 auto;
      -o-object-fit: cover;
         object-fit: cover;
      display: block;
      position: relative;
    }
    /*HOME PRESTA 900*/
    .home-presta-wrapper{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-content: center;
      justify-items: center;
      padding:0;
    }
    /*HOME TEACHING 900*/
  
    .home-teaching-wrapper {
      padding: 0em;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      justify-items: center;
      flex-direction: column-reverse;
      flex-wrap: nowrap;
     
    }
    .home-share-wrapper {
      padding: 0em;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      justify-items: center;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .home-teaching-image{
      display: block;
      width: 80%;
      height: 80%;
      -o-object-fit: contain;
         object-fit: contain;
      position: relative;
      z-index: 2;
    }
    /*NEW HOME*/

    .home-intro-image{
      display: block;
    width: 80%;
    height: 80%;
    margin: 0 auto;
    -o-object-fit: contain;
       object-fit: contain;
    position: relative;
    z-index: 2;
    }
    .home-intro-title-wrapper{
      height: 0;
    }
 
    .home-intro-wrapper{
      display: flex;
      flex-direction: column-reverse;
      padding: 0;
      background: linear-gradient(90deg, rgba(255,255,255,0.10696778711484589) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 87%, rgba(255,255,255,0) 95%, rgba(255,255,255,0) 100%);
    }
    .home-trans-mask{
      height: 180px;
      width: 100%;
      margin: 0 auto;
      margin-top: 4em;
    }
    .background-home-first{
   
      position: relative;
      display: relative;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      -o-object-fit: contain;
         object-fit: contain;
    }
    /**/
    .alaune-container{
      display: block;
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 3em;
    }
    
  
    
    .main-actu-compo-articles-wrapper{
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 90%;
      align-items: center;
      justify-items: center;
      justify-content: center;
      align-content: center;
      grid-template-rows: auto;
      margin-bottom: 3em;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
    }
    .main-actu-block-title, .main-actu-block-subtitle{
      font-size: 1.2em;
    }
    .main-actu-compo-textblock{
      font-size: 1em;
    }
    .wrapper-actualities{
      position: relative;
      display: block;
      width: 90%;
      margin: 0 auto;
   
    }
    .main-actu-compo-title2{
      font-size:50px;
    }
    .p01-video-article-outcard{ 
      width: 300px;
      height: auto;
      -o-object-fit: cover;
         object-fit: cover;
      transform: scale(1) translateX(0);
      transition: transform 1s ease-out;
      position: relative;
      perspective: 2000px;
      perspective-origin: left;
     
    }
   
    .price-phone-wrapper{
      display: flex;
      flex-direction: row;
    }
    .price-phone-wrapper:nth-child(2n){
      display: flex;
      flex-direction: row-reverse;
    }

    .price-img-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .price-fixed-background {
      position: relative;
      z-index: -1;
      display: flex;
      margin: 0 auto;
      width: 100%;
      height: inherit;
      background-image: url("../images/common/priceBackground.jpg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-attachment: fixed;
      background-position-x: center;
      background-position-y: center;
    }
   
  
  
    .p01-card-container-reverse{
      border: none;
    }
    .p01-card-container{
      border: none;
    }
    .enseignement-article-wrapper:nth-child(1){
      margin-top: 0;
    }
    .enseignement-text{
      width: 96%;
      margin: 0 auto;
    }
    
    .title-span{
      font-size: 20px;
    }
    .article-images{
      margin: 0 auto;
      position: relative;
      width: 97%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(110px, 2fr));
     
    }
    .p01-image-article-outcard:nth-child(2){
      transform: scale(1) translateX(-34px) translateY(67px);

    }
    .p01-image-article-outcard:nth-child(3){
      transform: scale(0.9) translateX(8px) translateY(0px);
  
    }
    .p01-image-article-outcard:nth-child(5){
      transform: scale(1) translateX(8px) translateY(36px);
 
    }
   /*  .p01-image-article-outcard:nth-child(4){
      transform: translateX(-6px) translateY(70px);   

    } */
    .p01-image-article-outcard:nth-child(4){
      transform: scale(0.8) translateX(-6px) translateY(70px);
    }
    .text-article{
      align-items: flex-start;
    }
    .p01-article-row{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      
    }
    .p01-article-row-reverse{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
   
    h1.gold{
    font-size: 1.5em;
    }

    

    .enseignement-article-container {
      display: block;
      width: 80%;
      text-align: justify;
      height: -moz-fit-content;
      height: -webkit-fit-content;
      height: fit-content;
      min-height: 100%;
  }
    .intro-presta-wrapper{
      margin-top: 2em;
      display: block;
      margin: 0 auto;
      width: 100%;
      justify-content: center;
      align-items: center;
      justify-items: stretch;
      line-height: 2em;
    }
    .logo-footer{
      width: 60px;
      height: 60px;
      padding: 1em;
    }

   .separate{
    display: none;
   }
  
    .sign-form-container{
      display: block;
      position: relative;
      width: 90%;
      margin: 0 auto;
      
    }
 
    .booking-form{
      width: 80%;
    }
    .presta-h2{
      font-size: 35px;
      padding:0;
    }
    .presta-h2-embeded{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-around;
      align-items: center;
      color: var(--appDarkGold);
      width: 100%;
      margin: 0 auto;
      margin-top: 2em;
      margin-bottom: 1em;
      padding: 0.5em;
      border: none;
      border-bottom: 2px solid var(--appDarkBlue);
      -webkit-filter: drop-shadow(0px 3px 10px #8b8b94);
              filter: drop-shadow(0px 3px 10px #8b8b94);
     /*  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    }
    .contact-form-container{
      position: relative;  
      flex-direction: column;   
      display: flex;
      width: 100%;
      margin: 0 auto;
    }
    .article-card-wrapper-right{
      transform: translateX(-5em);
    }
    .equilys-plume{
      transform: scale(0.8);
    }
    .hero-photo, .com-hero-photo{
      width: 100%;
      height: auto;
    }
    .prez-article-title-image-right{
      width: inherit;
      height: inherit;
      position: absolute;
      -o-object-fit: cover;
         object-fit: cover;
      z-index: 5;
      top: calc(var(--articleHeight)*1/6);
      right: calc(var(--articleWidth)/-1.5);
    }
    .article-prez-wrapper-right{
      padding-right: 0;
    
    }
    .article-card-wrapper:nth-child(2){
      top: 60px;
      left: calc(var(--prezArticleImageWidth)/-4);
    }
    .home-article-wrapper{
      display: grid;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      grid-template-columns: none;
      grid-template-rows: repeat( auto-fit, minmax(calc(var(--articleWidth)), 1fr) );
      width: 80%;
      margin: 0 auto;
      margin-left: calc(var(--prezArticleImageWidth)/2);
    }
  .home-title-mask{
    width: calc(var(--homeImageWidth));
    height: calc(var(--homeImageHeight)*1/6);
    background-color: var(--appCaution);
    position: absolute;
    top: calc(var(--homeImageHeight)/2);
   
  }
  .home-title-image{
    width: calc(var(--homeImageWidth)*1/2);
    height: calc(var(--homeImageHeight)*1/2);
    position: relative;
    -o-object-fit: cover;
       object-fit: cover;
 
  }
  .hero-text h2{
    text-align: center;
    font-size: 1em;
    color: var(--appDarkBlue);
  }
  .hero-text{
    position: absolute;
    display: flex;
    z-index: 4;
    top: inherit;
    left: inherit;
    margin-top: inherit;
    width: inherit;
    height: inherit;
    /* background-color: rgba(60, 135, 234, 0.51); */
    background-image: linear-gradient(360deg, rgba(4 55 96 / 51%), rgba(255,0,0,0) 80.71%);
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .hero-com{
    position: absolute;
    display: flex;
    z-index: 4;
    top: inherit;
    left: inherit;
    margin-top: inherit;
    width: inherit;
    height: 100%;
    /* background-color: rgba(60, 135, 234, 0.51); */
    background-image: linear-gradient(360deg, rgba(4 55 96 / 51%), rgba(255,0,0,0) 80.71%);
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
  }
  h1.hero-title{
    color: var(--appWhiteColor);
    font-size: var(--h1Hero);
    font-weight: 200;
    margin: 0 auto;
    position: relative;
  }
 
h2.hero-title{
  font-size: var(--h2Hero);
}
  .burger-cross:active{
    display: none;
   }
  
  .burger-cross-animated{
    color: var(--appGreenColor);
    display: block;
    margin-left: 85%;
    height: 2em;
    width: 2em;
    -webkit-animation: 0.5s ease-in-out 0s 3 alternate rotate;
            animation: 0.5s ease-in-out 0s 3 alternate rotate; 
  
  }
 
  .navbar-container-scroll{
    position: fixed;
    background-color: var(--navBckgColor);
    top: 0;
    left: 0;
    margin: 0;
    display: inline-flex;
    width: 100vw;
    height: var(--telNavHeight);
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom: var(--appMainColor) 2px solid;
    transition : top 0.5s linear, -webkit-filter 0.5s linear;
  }
  .navbar-container{
    position: fixed;
    /* background-color: var(--navBckgColor); */
   /*  top: var(--navHeight); */
    left: 0;
    margin: 0;
    display: inline-flex;
    width: 100vw;
    height: var(--telNavHeight);
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom: var(--appMainColor) 2px solid;
    transform-origin: top left;
    transition: top 0.5s linear, -webkit-filter 0.5s linear;
    transition: top 0.5s linear, filter 0.5s linear;
    transition: top 0.5s linear, filter 0.5s linear, -webkit-filter 0.5s linear;
    -webkit-transition: top 0.5s linear, filter 0.5s linear;
  }

  .logo-menu{
    display: block;
    position: absolute;    
    height: 4em;
    width: auto;
    top: 0;
    right: 0;

  }
 
  .li-menu, .li-submenu{
    display: block;
    color: var(--fontMainColor);
    text-decoration: none;
    font-size: var(--mediumFontSize);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 1000;
  }
  .li-menu-closed{
    display: none;
    color: var(--fontMainColor);
    text-decoration: none;
    font-size: var(--mediumFontSize);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 1.5em;

  }
  .li-menu-activ{
    display: block;
    color:var(--fontMedColor);
    text-decoration: none;
    font-size: var(--mediumFontSize);
    transform: scaleX(1.1);
    -webkit-transform: scaleX(1.1);
    transition: transform 2s color 2s;
    -webkit-transition: transform 2s color 2s;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1.5em;
    z-index: 1000;
   
  }
  .opened-navbar{
    display: block;
    position: absolute;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 6px 9px rgb(50 50 93 / 6%), 0 2px 5px rgb(0 0 0 / 8%);
    border-bottom: var(--appMainColor) 2px solid;
    border-top: var(--appMainColor) 2px solid;
    max-width: 100vw;
    margin:0;
    top: 0;
    left: 0; 
    transition: left 1s;
    padding-left: 0;
  }
  .navbar-wrapper{
    display: block;
    position: absolute;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 6px 9px rgb(50 50 93 / 6%), 0 2px 5px rgb(0 0 0 / 8%);
    border-bottom: var(--appMainColor) 1px solid;
    border-top: var(--appMainColor) 2px solid;
    max-width: 100vw;
    margin: 0;
    top: 0;
    left: -50em;
    transition: left 2s 1s;
    
  }
  .menu-tel{  
    position: absolute;
    display: block;
    text-align: left;
    top: 0;
    left:0;
    width: auto;
    cursor: pointer;
    z-index: 4;
    height: var(--navHeight);
    width: auto;
    transform-origin: top left;
    animation: 1.08s ease-in-out 0s infinite alternate-reverse pop; 
  }
  .menu-tel-nopop{  
    position: absolute;
    display: block;
    text-align: left;
    top:0;
    /* top: calc(var(--navHeight)*2); */
    left:0;
    width: auto;
    cursor: pointer;
    z-index: 4;
    height: var(--navHeight);
    width: auto;
    transform-origin: top left;
    transition: top 0.5s linear, -webkit-filter 0.5s linear;
    transition: top 0.5s linear, filter 0.5s linear;
    transition: top 0.5s linear, filter 0.5s linear, -webkit-filter 0.5s linear;
    -webkit-transition: top 0.5s linear, filter 0.5s linear;
  }
  .menu-tel-nopop:hover{
    -webkit-filter: brightness(1.2);
    filter: brightness(1.2);
    transform-origin: center;
    transform: scale(1.08);
    transition: transform 1s linear;
    cursor: pointer;
  }
  
  @-webkit-keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
 

  @-webkit-keyframes pop{
    0% {
    
      transform: scale(1);
    }  
    
    100%{
      transform: scale(1.05);
      -webkit-filter: brightness(1.2);
              filter: brightness(1.2);
    }
  }

  @keyframes pop{
    0% {
    
      transform: scale(1);
    }  
    
    100%{
      transform: scale(1.05);
      -webkit-filter: brightness(1.2);
              filter: brightness(1.2);
    }
  }
  
  .burger-cross{
    margin-left: 85%;
    height: 2em;
    width: 2em; 
    display: block;
    color: var(--appGreenColor);
  }
  .burger-cross:hover{
    display: block;
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
    transform-origin: center;
    cursor: pointer;
  }
  
  
  .menu-tel:hover{
    display: block;
    -webkit-filter: brightness(1.5) contrast(0.5);
            filter: brightness(1.5) contrast(0.5);
    transition: -webkit-filter 1s;
    transition: filter 1s;
    transition: filter 1s, -webkit-filter 1s;
    -webkit-transition: filter 1s;
  }  
}