@keyframes scalepopBubble {
  0% {
    transform: scale(0) translateY(-200px);
    opacity: 0;
  }

  50% {
    transform: scale(0) translateY(-100px);
    opacity: 0.5;
  }
  
  100% {
    opacity: 1;
    transform: scale(1) translateY(0px);
  }
}

.bubble-wrapper{
  width: 200px;
  height: 200px;
  position: relative;
  -webkit-animation: 1.5s scalepopBubble;
  animation: 1.5s scalepopBubble;
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
}

.bubble-wrapper-small{
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0 auto;
}
.ball {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: relative;
  background: radial-gradient(circle at bottom, #81e8f6, #76deef 10%, #055194 80%, #1472c9 100%); 
  opacity: 0.5;
  z-index: 5;
}
  .ball:before {
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 100%;
    background: radial-gradient(circle at top, white, rgba(255, 255, 255, 0) 58%);
    -webkit-filter: blur(5px);
    filter: blur(5px);
    z-index: 6; 
}
  .ball:after {
    content: "";
    position: absolute;
    display: none;
    top: 5%;
    left: 10%;
    width: 80%;
    height: 80%;
    border-radius: 100%;
    -webkit-filter: blur(1px);
    filter: blur(1px);
    z-index: 6;
    -webkit-transform: rotateZ(-30deg);
    transform: rotateZ(-30deg); }
  .ball .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
    -webkit-transform: rotateX(90deg) translateZ(-160px);
    transform: rotateX(90deg) translateZ(-160px);
    z-index: 7; 
}
  .ball.plain {
    background: black; }
    .ball.plain:before, .ball.plain:after {
      display: none; 
    }
  .ball.bubble {
    width: inherit;
    margin: 0 auto;
    height: inherit;
    border: 3px solid var(--appGreenColor);
    background: radial-gradient(circle at 50% 55%, rgba(240, 245, 255, 0.9), rgba(240, 245, 255, 0.9) 40%, rgba(225, 238, 255, 0.8) 60%, rgba(7, 24, 46, 0.8));
    animation: bubble-rotate 2s linear infinite;
  }
  .ball.presta.bubble {
    width: inherit;
    margin: 0 auto;
    height: inherit;
    border: 3px solid var(--appDarkBlue);
    background: radial-gradient(circle at 50% 55%, rgba(240, 245, 255, 0.9), rgba(240, 245, 255, 0.9) 40%, rgba(225, 238, 255, 0.8) 60%, rgba(7, 24, 46, 0.8));
    animation: bubble-rotate 2s linear infinite;
  }
    .ball.bubble:after, .ball.bubble.presta:after{
      display: block;
      background: radial-gradient(circle at 50% 80%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 74%, white 80%, white 84%, rgba(255, 255, 255, 0) 100%); }

    .bubble-img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
}
.bubble-video{
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  top: 0;
  clip-path: circle();
}
.bubble-video-wrapper{
  width: 200px;
  height: 200px;
  position: relative;
  margin: 0 auto;
}
.bubble-icon{
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.75);
  color: var(--appDarkGold);
}
.bubble-icon-small{
  position: absolute;
  top: 20%;
  left: 20%;
  width: 70%;
  height: 70%;
  transform: translateX(35px) translateY(15px);
  color: var(--appDarkGold);
}
.stage {
  width: inherit;
  height: inherit;
  display: inline-block;
  /* margin: 20px; */
  position: relative;
  perspective: 3000px; 
  perspective-origin: center;
}
.stage body {
  width: inherit;
 /*  margin: 20px auto; */
  background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2) 0%, rgba(255, 255, 255, 0.5) 40%, #ffffff 100%);
  background-repeat: no-repeat;
}

@keyframes bubble-rotate {
    0% {
        transform: rotateZ(0deg);
    }
    50% {
        transform: rotateZ(180deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
    
}

@-webkit-keyframes bubble-anim {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }

  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
    transform: scaleY(0.95) scaleX(1.05); }

  48% {
    -webkit-transform: scaleY(1.1) scaleX(0.9);
    transform: scaleY(1.1) scaleX(0.9); }

  68% {
    -webkit-transform: scaleY(0.98) scaleX(1.02);
    transform: scaleY(0.98) scaleX(1.02); }

  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
    transform: scaleY(1.02) scaleX(0.98); }

  97%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes bubble-anim {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }

  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
    transform: scaleY(0.95) scaleX(1.05); }

  48% {
    -webkit-transform: scaleY(1.1) scaleX(0.9);
    transform: scaleY(1.1) scaleX(0.9); }

  68% {
    -webkit-transform: scaleY(0.98) scaleX(1.02);
    transform: scaleY(0.98) scaleX(1.02); }

  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
    transform: scaleY(1.02) scaleX(0.98); }

  97%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

    @media (min-width: 1086px) and (max-width: 1450px){
     
    }
    @media (min-width: 901px) and (max-width: 1085px){
  
    }
    @media (max-width: 900px) {
      .bubble-wrapper{
        width: 150px;
        height: 150px;
      }
    }
  
  
